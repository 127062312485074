import React, { useState, useEffect, useCallback, useRef } from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import CircularProgress from '@mui/material/CircularProgress';
import HistoryIcon from '@mui/icons-material/History';
// import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import FilteredPostsDisplay from './FilteredPostsDisplay';
import './CompanySearch.css';

const SEARCH_HISTORY_KEY = 'companySearchHistory';
const MIN_CHARS_FOR_SEARCH = 2;
const DEBOUNCE_DELAY = 300;
const MAX_HISTORY_ITEMS = 5;

const CompanySearch = ({ topics, flaskServerAddress }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const [showNoResults, setShowNoResults] = useState(false);
    const [error, setError] = useState(null);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [searchHistory, setSearchHistory] = useState([]);
    // const [popularSearches] = useState(['product feedback', 'pricing', 'competitors', 'features']); // Will be fetched from backend later
    const abortController = useRef(null);
    const searchInputRef = useRef(null);
    const searchTimeout = useRef(null);
    const noResultsTimeout = useRef(null);
    const searchContainerRef = useRef(null);

    // Load search history on mount
    useEffect(() => {
        const history = localStorage.getItem(SEARCH_HISTORY_KEY);
        if (history) {
            setSearchHistory(JSON.parse(history));
        }
    }, []);

    // Handle clicks outside of search container to close suggestions
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchContainerRef.current && !searchContainerRef.current.contains(event.target)) {
                setShowSuggestions(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // Save search history
    const updateSearchHistory = useCallback((query) => {
        setSearchHistory(prevHistory => {
            const newHistory = [query, ...prevHistory.filter(item => item !== query)]
                .slice(0, MAX_HISTORY_ITEMS);
            localStorage.setItem(SEARCH_HISTORY_KEY, JSON.stringify(newHistory));
            return newHistory;
        });
    }, []);

    const performSearch = useCallback(async (query) => {
        if (!query.trim() || query.length < MIN_CHARS_FOR_SEARCH || !topics) {
            setSearchResults([]);
            setIsSearching(false);
            setShowNoResults(false);
            return;
        }

        // Cancel any pending searches
        if (abortController.current) {
            abortController.current.abort();
        }
        abortController.current = new AbortController();

        // Clear any pending "no results" timeout
        if (noResultsTimeout.current) {
            clearTimeout(noResultsTimeout.current);
        }

        setIsSearching(true);
        setShowNoResults(false);
        setError(null);

        try {
            const topicIds = topics.map(topic => topic.id).join(',');
            const encodedQuery = encodeURIComponent(query);
            const minRelevance = 4;

            const response = await fetch(
                `${flaskServerAddress}topic-relevance-search/?topic_ids=${topicIds}&query=${encodedQuery}&min_relevance=${minRelevance}&limit=100`,
                { signal: abortController.current.signal }
            );

            if (!response.ok) {
                throw new Error('Search request failed');
            }

            const data = await response.json();
            setSearchResults(data);
            // Add a delay before showing "no results" message
            if (data.length === 0) {
                noResultsTimeout.current = setTimeout(() => {
                    setShowNoResults(true);
                }, 500); // 500ms delay
            }
            updateSearchHistory(query);
        } catch (error) {
            if (error.name === 'AbortError') {
                return;
            }
            setError('An error occurred while searching. Please try again.');
            setSearchResults([]);
        } finally {
            setIsSearching(false);
        }
    }, [topics, flaskServerAddress, updateSearchHistory]);

    // Debounced search
    useEffect(() => {
        if (searchTimeout.current) {
            clearTimeout(searchTimeout.current);
        }

        if (searchQuery.length >= MIN_CHARS_FOR_SEARCH) {
            setIsSearching(true); // Set loading state immediately
            searchTimeout.current = setTimeout(() => {
                performSearch(searchQuery);
            }, DEBOUNCE_DELAY);
        } else {
            setSearchResults([]);
            setIsSearching(false);
        }

        return () => {
            if (searchTimeout.current) {
                clearTimeout(searchTimeout.current);
            }
        };
    }, [searchQuery, performSearch]);

    // Clean up timeouts on unmount
    useEffect(() => {
        return () => {
            if (noResultsTimeout.current) {
                clearTimeout(noResultsTimeout.current);
            }
        };
    }, []);

    const handleSearchInputChange = (e) => {
        const value = e.target.value;
        setSearchQuery(value);
        // Only show suggestions if there's no search results and we have history
        setShowSuggestions(value.length > 0 && searchResults.length === 0 && searchHistory.length > 0);
    };

    const handleSuggestionClick = (suggestion) => {
        setSearchQuery(suggestion);
        setShowSuggestions(false);
        performSearch(suggestion);
    };

    const handleClearSearch = () => {
        setSearchQuery('');
        setSearchResults([]);
        setShowSuggestions(false);
        setIsSearching(false);
        setShowNoResults(false);
        if (noResultsTimeout.current) {
            clearTimeout(noResultsTimeout.current);
        }
        searchInputRef.current?.focus();
    };

    return (
        <div className="company-search" ref={searchContainerRef}>
            <div className="search-container" role="search">
                <TextField
                    fullWidth
                    inputRef={searchInputRef}
                    value={searchQuery}
                    onChange={handleSearchInputChange}
                    onFocus={() => {
                        // Only show suggestions if there's no search results and we have history
                        if (searchResults.length === 0 && searchHistory.length > 0) {
                            setShowSuggestions(true);
                        }
                    }}
                    placeholder="Search across all topics..."
                    aria-label="Search company topics"
                    autoComplete="off"
                    inputProps={{
                        autoComplete: 'off',
                        autoCorrect: 'off',
                        autoCapitalize: 'off',
                        spellCheck: 'false'
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon color="action" />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                {isSearching ? (
                                    <CircularProgress size={20} />
                                ) : searchQuery && (
                                    <IconButton
                                        onClick={handleClearSearch}
                                        edge="end"
                                        aria-label="Clear search"
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                )}
                            </InputAdornment>
                        ),
                    }}
                />

                {/* Only show suggestions if we have history and no results */}
                {showSuggestions && searchHistory.length > 0 && searchResults.length === 0 && (
                    <div className="search-suggestions" role="listbox">
                        <div className="suggestion-section">
                            <h3>
                                <HistoryIcon fontSize="small" />
                                Recent Searches
                            </h3>
                            {searchHistory.map((item, index) => (
                                <div
                                    key={`history-${index}`}
                                    className="suggestion-item"
                                    onClick={() => handleSuggestionClick(item)}
                                    role="option"
                                    tabIndex={0}
                                >
                                    {item}
                                </div>
                            ))}
                        </div>
                        {/* Popular searches section commented out for now
                        <div className="suggestion-section">
                            <h3>
                                <TrendingUpIcon fontSize="small" />
                                Popular Searches
                            </h3>
                            {popularSearches.map((item, index) => (
                                <div
                                    key={`popular-${index}`}
                                    className="suggestion-item"
                                    onClick={() => handleSuggestionClick(item)}
                                    role="option"
                                    tabIndex={0}
                                >
                                    {item}
                                </div>
                            ))}
                        </div>
                        */}
                    </div>
                )}

                {error && (
                    <div className="search-error" role="alert">
                        {error}
                    </div>
                )}

                <div className="search-results">
                    {searchResults.length > 0 ? (
                        <FilteredPostsDisplay
                            posts={searchResults}
                            showBookmarkButton={true}
                            query={searchQuery}
                            hideHeader={true}
                            showSortControls={true}
                            defaultSortMethod="mostRelevant"
                        />
                    ) : searchQuery && !isSearching && searchQuery.length >= MIN_CHARS_FOR_SEARCH && showNoResults && (
                        <div className="no-results-message" role="status">
                            No results found for "{searchQuery}"
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CompanySearch; 