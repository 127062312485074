import { subYears, subMonths, startOfYear, startOfMonth } from 'date-fns';

// Convert time range string to start date
export const getStartDateFromTimeRange = (timeRange) => {
    const now = new Date();
    
    switch (timeRange) {
        case '1y':
            return subYears(now, 1);
        case '3y':
            return subYears(now, 3);
        case '5y':
            return subYears(now, 5);
        case '10y':
            return subYears(now, 10);
        case 'ytd':
            return startOfYear(now);
        case 'all':
            return new Date(0); // Beginning of time
        default:
            return subYears(now, 3); // Default to 3 years
    }
};

// Filter posts by date range
export const filterPostsByDateRange = (posts, startDate, endDate = new Date()) => {
    return posts.filter(post => {
        const postDate = new Date(post.time * 1000);
        return postDate >= startDate && postDate <= endDate;
    });
};

// Get posts for a specific time period around a target date
export const getPostsForTimePeriod = (posts, targetDate, periodMonths = 6) => {
    const endDate = new Date(targetDate);
    const startDate = subMonths(endDate, periodMonths);
    
    return filterPostsByDateRange(posts, startDate, endDate);
};

// Filter posts by product
export const filterPostsByProduct = (posts, selectedProduct, topics) => {
    if (!selectedProduct || !topics) return posts;
    
    const selectedTopic = topics.find(t => t.topic === selectedProduct);
    if (!selectedTopic) return posts;
    
    // Get posts for the selected product
    const productPosts = new Set(selectedTopic.posts.map(p => p.id));
    
    // Return only posts that belong to the selected product
    return posts.filter(post => post && post.id && productPosts.has(post.id));
};

// Get competitor posts for a product
export const getCompetitorPosts = (selectedProduct, topics) => {
    if (!selectedProduct || !topics) return [];
    
    const selectedTopic = topics.find(t => t.topic === selectedProduct);
    if (!selectedTopic?.competitors) return [];
    
    return selectedTopic.competitors.flatMap(competitor => 
        competitor.posts || []
    );
};

// Main filtering function that combines all filters
export const filterPosts = ({
    posts,
    selectedProduct,
    topics,
    timeRange,
    startDate,
    endDate,
    includeCompetitors = false
}) => {
    if (!posts) return [];
    let filteredPosts = [...posts];
    
    // Apply product filter if we have a selected product
    if (selectedProduct && topics) {
        filteredPosts = filterPostsByProduct(filteredPosts, selectedProduct, topics);
        
        // Add competitor posts if requested
        if (includeCompetitors) {
            const competitorPosts = getCompetitorPosts(selectedProduct, topics);
            filteredPosts = [...filteredPosts, ...competitorPosts];
        }
    }
    
    // Apply date filter if we have a time range
    if (timeRange) {
        const filterStartDate = startDate || getStartDateFromTimeRange(timeRange);
        filteredPosts = filterPostsByDateRange(filteredPosts, filterStartDate, endDate);
    }
    
    return filteredPosts;
}; 