import { useState, useEffect, useCallback, useMemo } from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { startOfMonth, format, parse, subYears, subMonths } from 'date-fns';
import '../styles/NPSPage.css';
import FilteredPostsDisplay from './FilteredPostsDisplay';
import NPSChart from './NPSChart';
import { computeBayesianMonthlyNPS, NPS_CONFIG } from '../functions/bayesianNPS';

import { 
    filterPosts, 
    getStartDateFromTimeRange, 
    getPostsForTimePeriod,
    filterPostsByDateRange
} from '../utils/dateFilters';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const CHART_COLORS = {
    main: 'rgb(47, 71, 184)',  // dark blue for main product
    competitors: [
        'rgb(75, 192, 192)',     // cyan blue
        'rgb(138, 43, 226)',    // Purple
        'rgb(134, 0, 46)',     // dark red
        'rgb(255, 99, 71)',     // Tomato red
        'rgb(50, 205, 50)',     // Lime green
        'rgb(255, 140, 0)',     // Dark orange
    ]
};

const NPSPage = ({posts, stat_name, title, topics}) => {
    const [data, setData] = useState([]);
    const [chartData, setChartData] = useState(null);
    const [postsReferenced, setPostsReferenced] = useState([]);
    const [showPostDisplay, setShowPostDisplay] = useState(false);
    const [showSettings, setShowSettings] = useState(false);
    const [showConfidenceIntervals, setShowConfidenceIntervals] = useState(false);

    const [baselineNPS, setBaselineNPS] = useState(44); // Prior mean NPS
    const [padding, setPadding] = useState(20); // Prior strength
    
    const [averages, setAverages] = useState({ 
        year: { nps: null, raw: null, noPaddingNps: null, count: null },
        threeYears: { nps: null, raw: null, noPaddingNps: null, count: null },
        fiveYears: { nps: null, raw: null, noPaddingNps: null, count: null }
    });
    
    const [timeRange, setTimeRange] = useState(null);
    const [selectedCompetitors, setSelectedCompetitors] = useState(new Set());
    const [competitorData, setCompetitorData] = useState({});
    const [showCompetitors, setShowCompetitors] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState("");
    const [selectedTimePeriod, setSelectedTimePeriod] = useState(null);

    // Use the thresholds from the centralized NPS_CONFIG
    const { PROMOTER_THRESHOLD, DETRACTOR_THRESHOLD } = NPS_CONFIG;

    const handlePointClick = useCallback((event, elements, chart) => {
        if (elements.length > 0) {
            const { datasetIndex, index } = elements[0];
            const pointData = chart.data.datasets[datasetIndex].data[index];
            const { x: month } = pointData;
            const productName = chart.data.datasets[datasetIndex].label;
            
            // Skip if this is a confidence band dataset
            if (productName.includes('Confidence Band')) return;
            
            console.log('Point clicked:', { month, productName, pointData });
            
            // Parse the month string and get first and last day of that month
            const [year, monthStr] = month.split('-');
            const monthNum = parseInt(monthStr) - 1; // 0-based month
            const startDate = new Date(Date.UTC(parseInt(year), monthNum, 1));
            const endDate = new Date(Date.UTC(parseInt(year), monthNum + 1, 0)); // Last day of month
            
            console.log('Date range:', { startDate, endDate });

            // Find the topic that matches this product name
            const topic = topics.find(t => t.topic === productName);
            const competitor = !topic ? 
                topics.find(t => t.competitors?.some(c => c.topic === productName))?.competitors?.find(c => c.topic === productName) 
                : null;
            
            const sourcePosts = topic?.posts || competitor?.posts || [];
            console.log('Source posts:', sourcePosts.length);

            // Get posts for this specific month
            const periodPosts = sourcePosts
                .filter(post => {
                    const postDate = new Date(post.time * 1000);
                    return postDate >= startDate && postDate <= endDate;
                })
                .map(post => {
                    const assessment = post.post_assessments?.find(a => a?.type === stat_name);
                    if (!assessment) return null;

                    return {
                        ...post,
                        rating: assessment.rating,
                        confidence: assessment.confidence,
                        credibility: assessment.credibility,
                        explanation: assessment.explanation,
                        ratingName: stat_name,
                        productName: productName,
                        ratingType: stat_name
                    };
                })
                .filter(Boolean);

            console.log('Filtered period posts:', periodPosts.length);

            setSelectedTimePeriod({
                start: startDate,
                end: endDate,
                label: `Posts from ${format(startDate, 'MMMM yyyy')}`
            });
            
            setPostsReferenced(periodPosts);
            setShowPostDisplay(true);
        }
    }, [topics, stat_name]);

    const handleMetricCardClick = (period) => {
        const now = new Date();
        let startDate;
        let label;
        
        switch(period) {
            case 'year':
                startDate = subYears(now, 1);
                label = 'Posts from the Past Year';
                break;
            case 'threeYears':
                startDate = subYears(now, 3);
                label = 'Posts from the Past 3 Years';
                break;
            case 'fiveYears':
                startDate = subYears(now, 5);
                label = 'Posts from the Past 5 Years';
                break;
            default:
                return;
        }

        // Get posts for the selected product
        const selectedTopic = topics.find(t => t.id === selectedProduct);
        if (!selectedTopic) return;

        // Filter posts by time period and process them
        const periodPosts = selectedTopic.posts
            .filter(post => {
                const postDate = new Date(post.time * 1000);
                return postDate >= startDate && postDate <= now;
            })
            .map(post => {
                const assessment = post.post_assessments?.find(a => a?.type === stat_name);
                if (!assessment) return null;

                return {
                    ...post,
                    rating: assessment.rating,
                    confidence: assessment.confidence,
                    credibility: assessment.credibility,
                    explanation: assessment.explanation,
                    ratingName: stat_name,
                    productName: selectedTopic.topic,
                    ratingType: stat_name
                };
            })
            .filter(Boolean);

        console.log(`Found ${periodPosts.length} posts for period ${period}`);

        setSelectedTimePeriod({
            start: startDate,
            end: now,
            label
        });
        
        setPostsReferenced(periodPosts);
        setShowPostDisplay(true);
    };

    // Move chartConfig to useMemo
    const chartConfig = useMemo(() => ({
        responsive: true,
        onClick: handlePointClick,
        scales: {
            y: {
                min: -100,
                max: 100,
                title: {
                    display: true,
                    text: 'Net Promoter Score (Bayesian Estimate)',
                    font: {
                        size: 14,
                        weight: 'bold'
                    }
                }
            },
            x: {
                title: {
                    display: true,
                    text: 'Time Period',
                    font: {
                        size: 14,
                        weight: 'bold'
                    }
                }
            }
        },
        plugins: {
            title: {
                display: false
            },
            legend: {
                display: showCompetitors && Object.keys(competitorData).length > 1,
                position: 'top',
                align: 'end',
                labels: {
                    usePointStyle: true,
                    pointStyle: 'circle',
                    padding: 15,
                    boxWidth: 10,
                    boxHeight: 10,
                    filter: function(legendItem, data) {
                        return !legendItem.text.includes('Confidence Band');
                    }
                },
                onClick: function(e, legendItem, legend) {
                    const index = legendItem.datasetIndex;
                    const chart = legend.chart;
                    const meta = chart.getDatasetMeta(index);

                    meta.hidden = meta.hidden === null ? !chart.data.datasets[index].hidden : null;
                    
                    const mainLabel = chart.data.datasets[index].label;
                    chart.data.datasets.forEach((dataset, i) => {
                        if (dataset.label.includes('Confidence Band') && 
                            dataset.label.includes(mainLabel)) {
                            const confidenceMeta = chart.getDatasetMeta(i);
                            confidenceMeta.hidden = meta.hidden;
                        }
                    });

                    chart.update();
                }
            },
            tooltip: {
                callbacks: {
                    label: function(context) {
                        const datasetLabel = context.dataset.label || '';
                        if (datasetLabel.includes('Confidence Band')) {
                            return null;
                        }
                        const value = context.parsed.y;
                        const dataPoint = context.dataset.data[context.dataIndex];
                        const confidenceBounds = dataPoint.confidenceBounds;
                        if (confidenceBounds) {
                            return [
                                `${datasetLabel}: ${value.toFixed(1)}`,
                                `95% Credible Interval: [${confidenceBounds.lower.toFixed(1)}, ${confidenceBounds.upper.toFixed(1)}]`,
                                `Sample Size: ${dataPoint.count}`
                            ];
                        }
                        return `${datasetLabel}: ${value.toFixed(1)}`;
                    }
                }
            }
        }
    }), [showCompetitors, competitorData, handlePointClick]);

    const determineInitialTimeRange = (postsData) => {
        if (!postsData || postsData.length === 0) return '3y';

        // Get the main company's data (first dataset)
        const mainCompanyData = Object.values(competitorData)[0] || [];
        if (mainCompanyData.length === 0) return '3y';

        const now = new Date();
        const earliestPost = Math.min(...mainCompanyData.map(post => post.time));
        const earliestDate = new Date(earliestPost * 1000);
        const yearsDiff = (now - earliestDate) / (1000 * 60 * 60 * 24 * 365);

        // Select the appropriate time range that would include all data
        if (yearsDiff <= 1) return '1y';
        if (yearsDiff <= 3) return '3y';
        if (yearsDiff <= 5) return '5y';
        if (yearsDiff <= 10) return '10y';
        return 'all';
    };

    const filterPostsByProduct = (posts) => {
        if (!selectedProduct || !topics) return posts;
        
        const selectedTopic = topics.find(t => t.id === selectedProduct);
        if (!selectedTopic) return posts;
        
        // Return only posts that belong to the selected topic
        return selectedTopic.posts
            .map(post => {
                if (!post?.post_assessments) return null;
                const assessment = post.post_assessments.find(assessment => assessment?.type === stat_name);
                if (assessment) {
                    return {
                        ...assessment,
                        time: post.time,
                        id: post.id
                    };
                }
                return null;
            })
            .filter(item => item !== null && item?.rating !== null);
    };

    const fetchData = async () => {
        // Don't return early - if no product is selected, ProductSelector will handle it
        const filteredPosts = filterPosts({
            posts: posts || [],
            selectedProduct,
            topics,
            timeRange,
            includeCompetitors: showCompetitors
        });
        
        setData(filteredPosts);
        
        // Set initial time range based on data
        if (!timeRange) {
            setTimeRange(determineInitialTimeRange(filteredPosts));
        }
    };

    const handleDownloadCSV = () => {
        if (!chartData || !postsReferenced) return;
    
        // Original CSV content
        const aggregatedCsvContent = [
            'period,NPS,Count,Average Review Score',
            ...chartData.datasets[0].data
                .filter(item => item.y !== null)
                .map(item => {
                    const date = parse(item.x, 'yyyy-MM', new Date());
                    const formattedDate = format(date, 'M/d/yyyy');
                    const averageReviewScore = item.rawAverage !== null ? item.rawAverage.toFixed(1) : '';
                    return `${formattedDate},${item.y},${item.count},${averageReviewScore}`;
                })
        ].join('\n');

        // New CSV content for raw post data
        const rawPostsCsvContent = [
            'text,score,rationale,date,url',
            ...data.map(item => {
                const date = new Date(item.time * 1000);
                const formattedDate = format(date, 'M/d/yyyy');
                const combinedText = `${item.title || ''} ${item.text || ''}`.replace(/"/g, '""').replace(/<[^>]*>/g, '');
                const url = `scuttlebuttresearch.com/post/${item.id}`;
                return `"${combinedText}",${item.rating},"${item.explanation.replace(/"/g, '""')}",${formattedDate},"${url}"`;
            })
        ].join('\n');

        // Function to create and trigger download
        const downloadCsv = (content, filename) => {
            const blob = new Blob([content], { type: 'text/csv;charset=utf-8;' });
            const link = document.createElement('a');
            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        };
    
        // Download both CSVs
        downloadCsv(aggregatedCsvContent, `nps_scores_${stat_name}.csv`);
        downloadCsv(rawPostsCsvContent, `raw_posts_${stat_name}.csv`);
    };

    useEffect(() => {
        if (Array.isArray(posts) && posts.length > 0) {
            fetchData();
        }
    }, [posts, selectedProduct, showCompetitors, timeRange]);

    const handleClosePostsReferenced = () => {
        setShowPostDisplay(false);
        setPostsReferenced([]);
    };

    const handleSettingsClick = () => {
        setShowSettings(!showSettings);
    };

    const handleToggleChange = (event) => {
        setShowConfidenceIntervals(event.target.checked);
    };

    const handleTimeRangeChange = useCallback((newRange) => {
        setTimeRange(newRange);
    }, []);

    const filterDataByTimeRange = (data) => {
        if (timeRange === 'all') return data;
        
        const now = new Date();
        const yearsToShow = parseInt(timeRange);
        const cutoffDate = new Date(now.setFullYear(now.getFullYear() - yearsToShow, 0, 1));
        
        return data.filter(item => {
            const itemDate = parse(item.x, 'yyyy-MM', new Date());
            return itemDate >= cutoffDate;
        });
    };

    const getNPSColorClass = (nps) => {
        if (nps >= 30) return 'positive';
        if (nps <= 0) return 'negative';
        return 'neutral';
    };

    // Handle settings changes
    const handleSettingChange = (setting, value) => {
        switch (setting) {
            case 'baselineNPS':
                setBaselineNPS(value);
                break;
            case 'padding':
                setPadding(value);
                break;
            case 'showConfidenceIntervals':
                setShowConfidenceIntervals(value);
                break;
            case 'showSettings':
                setShowSettings(value);
                break;
        }
    };

    // Modify the useEffect that updates competitorData
    useEffect(() => {
        if (!topics) {
            console.log('No topics data available');
            return;
        }
        console.log('Processing topics:', topics);
        console.log('Selected product:', selectedProduct);
        console.log('Stat name:', stat_name);

        const newCompetitorData = {};
        
        if (selectedProduct) {
            // If a product is selected, show that product and its competitors
            const selectedTopic = topics.find(topic => topic.id === selectedProduct);
            console.log('Selected topic:', selectedTopic);
            
            if (selectedTopic) {
                // Add the main product's data
                const productPosts = (selectedTopic.posts || [])
                    .map(post => {
                        const assessment = post.post_assessments?.find(a => a?.type === stat_name);
                        if (assessment) {
                            return {
                                ...assessment,
                                time: post.time,
                                id: post.id
                            };
                        }
                        return null;
                    })
                    .filter(item => item !== null && typeof item.rating === 'number');

                console.log(`Found ${productPosts.length} posts for ${selectedTopic.topic}`);
                
                if (productPosts.length > 0) {
                    newCompetitorData[selectedTopic.topic] = productPosts;
                }

                // Add competitor data for this product
                if (selectedTopic.competitors) {
                    console.log('Processing competitors:', selectedTopic.competitors);
                    selectedTopic.competitors.forEach(competitor => {
                        if (!competitor?.posts) return;

                        const competitorPosts = competitor.posts
                            .map(post => {
                                const assessment = post.post_assessments?.find(a => a?.type === stat_name);
                                if (assessment) {
                                    return {
                                        ...assessment,
                                        time: post.time,
                                        id: post.id
                                    };
                                }
                                return null;
                            })
                            .filter(item => item !== null && typeof item.rating === 'number');

                        console.log(`Found ${competitorPosts.length} posts for competitor ${competitor.topic}`);
                        
                        if (competitorPosts.length > 0) {
                            newCompetitorData[competitor.topic] = competitorPosts;
                        }
                    });
                }
            }
        } else {
            // If no product is selected, show all products (but not competitors)
            console.log('No product selected, showing all products');
            topics.forEach(topic => {
                if (topic?.posts?.length > 0) {
                    const productPosts = topic.posts
                        .map(post => {
                            const assessment = post.post_assessments?.find(a => a?.type === stat_name);
                            if (assessment) {
                                return {
                                    ...assessment,
                                    time: post.time,
                                    id: post.id
                                };
                            }
                            return null;
                        })
                        .filter(item => item !== null && typeof item.rating === 'number');

                    console.log(`Found ${productPosts.length} posts for ${topic.topic}`);
                    
                    if (productPosts.length > 0) {
                        newCompetitorData[topic.topic] = productPosts;
                    }
                }
            });
        }

        console.log('Final competitor data:', newCompetitorData);
        setCompetitorData(newCompetitorData);
    }, [topics, selectedProduct, stat_name]);

    useEffect(() => {
        if (data.length > 0) {
            console.log('Processing chart data. Initial data length:', data.length);
            const datasets = [];
            let allLabels = new Set();
            
            // Process each product's data
            Object.keys(competitorData).forEach((productName, index) => {
                // Skip competitors if showCompetitors is false and this is not the main product
                if (index > 0 && !showCompetitors) {
                    console.log(`Skipping competitor ${productName} (showCompetitors is false)`);
                    return;
                }

                console.log(`Processing product: ${productName}`);
                const productPosts = competitorData[productName];
                if (!productPosts) {
                    console.log(`No posts found for ${productName}`);
                    return;
                }

                // First, compute Bayesian NPS on ALL data
                const monthlyData = aggregatePostsToMonthlyCounts(productPosts);
                console.log(`Monthly data for ${productName}:`, monthlyData);
                
                // Compute Bayesian NPS on all data
                const bayesResults = computeBayesianMonthlyNPS(
                    monthlyData, 
                    NPS_CONFIG.DEFAULT_PRIOR_ALPHA.map(alpha => alpha * NPS_CONFIG.DEFAULT_PRIOR_ALPHA_SCALAR),
                    NPS_CONFIG.DEFAULT_DISCOUNT_FACTOR, 
                    NPS_CONFIG.DEFAULT_SAMPLES
                );
                console.log(`Bayes results for ${productName}:`, bayesResults);

                // Then filter results based on selected time range
                const startDate = getStartDateFromTimeRange(timeRange);
                const filteredResults = startDate ? 
                    bayesResults.filter(r => {
                        const resultDate = parse(r.month, 'yyyy-MM', new Date());
                        return resultDate >= startDate;
                    }) : 
                    bayesResults;

                console.log(`Filtered results for ${productName}:`, filteredResults);

                // Collect all unique labels (months) from filtered results
                filteredResults.forEach(item => allLabels.add(item.month));

                const color = index === 0 ? CHART_COLORS.main : CHART_COLORS.competitors[index - 1];
                
                // Add the main line using filtered results
                datasets.push({
                    label: productName,
                    data: filteredResults.map(r => ({
                        x: r.month,
                        y: r.meanNPS,
                        confidenceBounds: {
                            lower: r.lowerCI,
                            upper: r.upperCI
                        },
                        count: monthlyData.find(m => m.month === r.month)?.counts.reduce((a, b) => a + b, 0) || 0,
                        rawAverage: null
                    })),
                    borderColor: color,
                    backgroundColor: color,
                    tension: 0.1,
                    borderWidth: 2,
                    order: 0
                });

                // Add confidence bands if enabled
                if (showConfidenceIntervals) {
                    // Add upper bound
                    datasets.push({
                        label: `${productName} (Confidence Band)`,
                        data: filteredResults.map(r => ({
                            x: r.month,
                            y: r.upperCI
                        })),
                        borderColor: color,
                        borderDash: [5, 5],
                        backgroundColor: index === 0 ? 'rgba(47, 71, 184, 0.1)' : 'rgba(75, 192, 192, 0.1)',
                        fill: false,
                        pointRadius: 0,
                        borderWidth: 1,
                        tension: 0.1,
                        order: 1
                    });
                    
                    // Add lower bound
                    datasets.push({
                        label: `${productName} (Confidence Band)`,
                        data: filteredResults.map(r => ({
                            x: r.month,
                            y: r.lowerCI
                        })),
                        borderColor: color,
                        borderDash: [5, 5],
                        backgroundColor: index === 0 ? 'rgba(47, 71, 184, 0.1)' : 'rgba(75, 192, 192, 0.1)',
                        fill: -1,
                        pointRadius: 0,
                        borderWidth: 1,
                        tension: 0.1,
                        order: 1
                    });
                }
            });

            // Sort labels chronologically
            const sortedLabels = Array.from(allLabels).sort();

            // Only set chart data if we have valid datasets
            if (datasets.length > 0 && datasets.some(d => d.data.length > 0)) {
                setChartData({
                    labels: sortedLabels,
                    datasets
                });
            } else {
                setChartData(null);
            }

            // Calculate period averages for the selected product
            if (selectedProduct) {
                const now = new Date();
                const yearAgo = subYears(now, 1);
                const threeYearsAgo = subYears(now, 3);
                const fiveYearsAgo = subYears(now, 5);

                // Get all posts for the selected product
                const selectedTopic = topics.find(t => t.id === selectedProduct);
                const allProductPosts = selectedTopic?.posts || [];

                // Function to calculate metrics for a specific time period
                const calculatePeriodMetrics = (startDate) => {
                    // Filter posts by time period
                    const periodPosts = allProductPosts.filter(post => {
                        const postDate = new Date(post.time * 1000);
                        return postDate >= startDate && postDate <= now;
                    });

                    if (periodPosts.length === 0) {
                        return {
                            nps: 0,
                            noPaddingNps: 0,
                            raw: 0,
                            count: 0
                        };
                    }

                    // Calculate raw metrics
                    let promoters = 0;
                    let detractors = 0;
                    let totalRating = 0;
                    let validPosts = 0;

                    periodPosts.forEach(post => {
                        const assessment = post.post_assessments?.find(a => a?.type === stat_name);
                        const rating = assessment?.rating;

                        if (typeof rating === 'number') {
                            if (rating > PROMOTER_THRESHOLD) promoters++;
                            else if (rating <= DETRACTOR_THRESHOLD) detractors++;
                            totalRating += rating;
                            validPosts++;
                        }
                    });

                    if (validPosts === 0) {
                        return {
                            nps: 0,
                            noPaddingNps: 0,
                            raw: 0,
                            count: 0
                        };
                    }

                    // Calculate raw NPS
                    const rawNPS = ((promoters - detractors) / validPosts) * 100;
                    
                    // Calculate raw average
                    const rawAverage = totalRating / validPosts;

                    // Apply Bayesian adjustment
                    const priorStrength = Math.max(padding, 5);
                    const posteriorWeight = validPosts / (validPosts + priorStrength);
                    const adjustedNPS = Math.round(
                        (posteriorWeight * rawNPS) + 
                        ((1 - posteriorWeight) * baselineNPS)
                    );

                    return {
                        nps: adjustedNPS,
                        noPaddingNps: Math.round(rawNPS),
                        raw: Number(rawAverage.toFixed(1)),
                        count: validPosts
                    };
                };

                setAverages({
                    year: calculatePeriodMetrics(yearAgo),
                    threeYears: calculatePeriodMetrics(threeYearsAgo),
                    fiveYears: calculatePeriodMetrics(fiveYearsAgo)
                });
            }
        }
    }, [data, stat_name, baselineNPS, padding, timeRange, competitorData, showCompetitors, showConfidenceIntervals, selectedProduct, topics]);

    // Check if competitors exist
    const hasCompetitors = useCallback(() => {
        if (!selectedProduct || !topics) return false;
        
        const selectedTopic = topics.find(topic => topic.id === selectedProduct);
        return selectedTopic?.competitors?.some(competitor => 
            competitor.posts?.some(post => 
                post?.post_assessments?.some(assessment => 
                    assessment?.type === stat_name && assessment?.rating !== null
                )
            )
        ) || false;
    }, [selectedProduct, topics, stat_name]);

    /**
     * Helper to group posts by month and produce rating counts for each rating from 0..10.
     * Returns an array (in ascending chronological order) of:
     * [ { month: "YYYY-MM", counts: [n0, n1, ..., n10] }, ... ]
     */
    function aggregatePostsToMonthlyCounts(posts) {
        console.log('Aggregating posts to monthly counts:', posts);
        
        // 1) Build a map from "YYYY-MM" => array of ratings
        const countsByMonth = {};

        posts.forEach(post => {
            if (!post || typeof post.time !== 'number' || typeof post.rating !== 'number') {
                console.log('Skipping invalid post:', post);
                return;
            }
            
            const rating = post.rating;
            if (rating < 0 || rating > 10) {
                console.log('Skipping post with invalid rating:', rating);
                return;
            }

            // Convert post.time (unix) to Date object
            const postDate = new Date(post.time * 1000);
            
            // Get the first day of the month for this post
            const year = postDate.getUTCFullYear();
            const month = postDate.getUTCMonth() + 1;
            const monthKey = `${year}-${month.toString().padStart(2, '0')}`;

            // Initialize array for this month if it doesn't exist
            if (!countsByMonth[monthKey]) {
                countsByMonth[monthKey] = new Array(11).fill(0);
            }

            // Add the rating to the appropriate bucket
            countsByMonth[monthKey][rating] += 1;
        });

        // 2) Turn that map into a sorted array
        const sortedMonths = Object.keys(countsByMonth).sort();
        const result = sortedMonths.map(m => ({
            month: m,
            counts: countsByMonth[m]
        }));
        
        console.log('Monthly aggregated results:', result);
        return result;
    }

    return (
        <div>
            {chartData && (
                <NPSChart 
                    // Chart data and display
                    chartData={chartData}
                    chartConfig={chartConfig}
                    showConfidenceIntervals={showConfidenceIntervals}
                    showCompetitors={showCompetitors}
                    
                    // Product selection
                    topics={topics}
                    selectedProduct={selectedProduct}
                    onProductChange={setSelectedProduct}
                    
                    // Time range
                    timeRange={timeRange}
                    onTimeRangeChange={handleTimeRangeChange}
                    
                    // Metrics
                    averages={averages}
                    onMetricCardClick={handleMetricCardClick}
                    
                    // Utility
                    getNPSColorClass={getNPSColorClass}
                    
                    // Competitor toggle
                    hasCompetitors={hasCompetitors()}
                    onToggleCompetitors={setShowCompetitors}
                    
                    // Credible intervals
                    setShowConfidenceIntervals={setShowConfidenceIntervals}
                />
            )}
            {showPostDisplay && postsReferenced.length > 0 && (
                <FilteredPostsDisplay 
                    posts={postsReferenced}
                    onClose={handleClosePostsReferenced}
                    id="nps-posts"
                    title={selectedTimePeriod?.label || "Referenced Posts"}
                    ratingDisplayName={stat_name}
                    autoScroll = {true}
                    ratingColorScheme={{ high: 7, low: 4 }}
                />
            )}
        </div>
    );
};

export default NPSPage;