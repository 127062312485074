import React, { useState, useEffect } from 'react';
import {
    Box,
    TextField,
    Button,
    Typography,
    Paper,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    Grid,
    Alert,
    Autocomplete,
    Chip
} from '@mui/material';

const TopicCompetitors = () => {
    const [topics, setTopics] = useState([]);
    const [newTopic, setNewTopic] = useState({
        topic: '',
        additional_text: '',
        search_terms: ''
    });
    const [selectedTopicA, setSelectedTopicA] = useState(null);
    const [selectedTopicB, setSelectedTopicB] = useState(null);
    const [relationshipType, setRelationshipType] = useState('direct');
    const [feedback, setFeedback] = useState(null);
    const [createdTopic, setCreatedTopic] = useState(null);

    // Fetch existing topics
    useEffect(() => {
        fetchTopics();
    }, []);

    const fetchTopics = async () => {
        try {
            const response = await fetch('http://localhost:3001/topics');
            const data = await response.json();
            setTopics(data);
        } catch (error) {
            console.error('Error fetching topics:', error);
            setFeedback({ type: 'error', message: 'Failed to fetch topics' });
        }
    };

    const handleCreateTopic = async (e) => {
        e.preventDefault();
        try {
            const searchTermsArray = newTopic.search_terms
                .split(',')
                .map(term => term.trim())
                .filter(term => term.length > 0);

            const response = await fetch('http://localhost:3001/topics', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    ...newTopic,
                    search_terms: searchTermsArray
                }),
            });

            const data = await response.json();

            if (response.ok) {
                setNewTopic({
                    topic: '',
                    additional_text: '',
                    search_terms: ''
                });
                setCreatedTopic(data);
                setFeedback({
                    type: 'success',
                    message: `Topic created successfully! UUID: ${data.id}`
                });
                fetchTopics(); // Refresh topics list
            } else {
                setFeedback({
                    type: 'error',
                    message: data.error || 'Failed to create topic'
                });
            }
        } catch (error) {
            console.error('Error creating topic:', error);
            setFeedback({
                type: 'error',
                message: 'Failed to create topic'
            });
        }
    };

    const handleCreateCompetitor = async (e) => {
        e.preventDefault();
        if (!selectedTopicA || !selectedTopicB) {
            setFeedback({
                type: 'error',
                message: 'Please select both topics'
            });
            return;
        }

        try {
            const response = await fetch('http://localhost:3001/competitors', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    topic_a_id: selectedTopicA.id,
                    topic_b_id: selectedTopicB.id,
                    relationship_type: relationshipType
                }),
            });

            const data = await response.json();

            if (response.ok) {
                setSelectedTopicA(null);
                setSelectedTopicB(null);
                setRelationshipType('direct');
                setFeedback({
                    type: 'success',
                    message: 'Competitor relationship created successfully!'
                });
            } else {
                setFeedback({
                    type: 'error',
                    message: data.error || 'Failed to create relationship'
                });
            }
        } catch (error) {
            console.error('Error creating competitor relationship:', error);
            setFeedback({
                type: 'error',
                message: 'Failed to create competitor relationship'
            });
        }
    };

    return (
        <Box sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom>
                Topic Competitors Management
            </Typography>

            {feedback && (
                <Alert 
                    severity={feedback.type} 
                    sx={{ mb: 2 }}
                    onClose={() => setFeedback(null)}
                >
                    {feedback.message}
                </Alert>
            )}

            {/* Display Created Topic Info */}
            {createdTopic && (
                <Paper sx={{ p: 2, mb: 3, bgcolor: '#f5f5f5' }}>
                    <Typography variant="h6" gutterBottom>
                        Last Created Topic
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography><strong>Name:</strong> {createdTopic.topic}</Typography>
                            <Typography><strong>UUID:</strong> {createdTopic.id}</Typography>
                            <Typography><strong>Description:</strong> {createdTopic.additional_text}</Typography>
                            {createdTopic.search_terms && (
                                <Box sx={{ mt: 1 }}>
                                    <Typography component="span"><strong>Search Terms:</strong> </Typography>
                                    {createdTopic.search_terms.map((term, index) => (
                                        <Chip 
                                            key={index} 
                                            label={term} 
                                            size="small" 
                                            sx={{ m: 0.5 }} 
                                        />
                                    ))}
                                </Box>
                            )}
                        </Grid>
                    </Grid>
                </Paper>
            )}

            {/* Create New Topic Form */}
            <Paper sx={{ p: 2, mb: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Create New Topic
                </Typography>
                <form onSubmit={handleCreateTopic}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Topic Name"
                                value={newTopic.topic}
                                onChange={(e) => setNewTopic({
                                    ...newTopic,
                                    topic: e.target.value
                                })}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Description"
                                value={newTopic.additional_text}
                                onChange={(e) => setNewTopic({
                                    ...newTopic,
                                    additional_text: e.target.value
                                })}
                                multiline
                                rows={3}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Search Terms"
                                value={newTopic.search_terms}
                                onChange={(e) => setNewTopic({
                                    ...newTopic,
                                    search_terms: e.target.value
                                })}
                                helperText="Enter search terms separated by commas"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button type="submit" variant="contained" color="primary">
                                Create Topic
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Paper>

            {/* Create Competitor Relationship Form */}
            <Paper sx={{ p: 2 }}>
                <Typography variant="h6" gutterBottom>
                    Create Competitor Relationship
                </Typography>
                <form onSubmit={handleCreateCompetitor}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <Autocomplete
                                value={selectedTopicA}
                                onChange={(event, newValue) => {
                                    setSelectedTopicA(newValue);
                                }}
                                options={topics}
                                getOptionLabel={(option) => `${option.topic} (${option.id})`}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Topic A"
                                        required
                                    />
                                )}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Autocomplete
                                value={selectedTopicB}
                                onChange={(event, newValue) => {
                                    setSelectedTopicB(newValue);
                                }}
                                options={topics}
                                getOptionLabel={(option) => `${option.topic} (${option.id})`}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Topic B"
                                        required
                                    />
                                )}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormControl fullWidth>
                                <InputLabel>Relationship Type</InputLabel>
                                <Select
                                    value={relationshipType}
                                    onChange={(e) => setRelationshipType(e.target.value)}
                                    required
                                >
                                    <MenuItem value="direct">Direct</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Button 
                                type="submit" 
                                variant="contained" 
                                color="primary"
                                disabled={!selectedTopicA || !selectedTopicB || selectedTopicA?.id === selectedTopicB?.id}
                            >
                                Create Relationship
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </Box>
    );
};

export default TopicCompetitors; 