import React, { useState, useEffect } from 'react';
import './KeyPeople.css';
import { useAuth } from '../components/AuthContext';

const KeyPeople = () => {
  const { PROD_SERVER } = useAuth();
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState('importance_desc');
  const [selectedOrg, setSelectedOrg] = useState('all');
  const [employmentFilter, setEmploymentFilter] = useState('all');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [keyPeople, setKeyPeople] = useState({});

  const { userId, token, serverAddress, flaskServerAddress } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(serverAddress+'key-people', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: new URLSearchParams({
            user_id: 'test123'
          }),
        });

        const data = await response.json();
        console.log('Key People Data:', data);
        setKeyPeople(data.data || {});
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to load key people data');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const organizations = [...new Set(Object.values(keyPeople)
    .map(person => person.organization)
    .filter(Boolean))].sort();

  const getScores = (person) => {
    const importanceWeight = 0.5;
    const confidenceWeight = 0.2;
    const followerWeight = 0.3;

    const scaledImportance = person.importance_rating / 10;
    const scaledConfidence = person.confidence / 10;
    const normalizedFollowers = Math.log10(person.followers_count + 1) / 6;
    
    const importanceScore = scaledImportance * 10 * importanceWeight;
    const confidenceScore = scaledConfidence * 10 * confidenceWeight;
    const followerScore = normalizedFollowers * 10 * followerWeight;
    
    const totalScore = importanceScore + confidenceScore + followerScore;

    return {
      total: totalScore,
      importance: importanceScore,
      confidence: confidenceScore,
      followers: followerScore
    };
  };

  const peopleArray = Object.values(keyPeople);

  const filteredPeople = peopleArray.filter(person => {
    const scores = getScores(person);
    if (scores.total < 4) return false;

    const matchesSearch = searchTerm === '' || 
      person.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      person.description?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      person.twitter_handle?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      person.organization?.toLowerCase().includes(searchTerm.toLowerCase());

    const matchesOrg = selectedOrg === 'all' || person.organization === selectedOrg;
    
    const matchesEmployment = employmentFilter === 'all' || 
      (employmentFilter === 'current' && person.is_current_employee) ||
      (employmentFilter === 'former' && !person.is_current_employee);

    return matchesSearch && matchesOrg && matchesEmployment;
  });

  const sortedPeople = [...filteredPeople].sort((a, b) => {
    const scoresA = getScores(a);
    const scoresB = getScores(b);
    
    switch (sortOrder) {
      case 'importance_desc':
        return scoresB.total - scoresA.total;
      case 'reach_desc':
        return b.followers_count - a.followers_count;
      default:
        return scoresB.total - scoresA.total;
    }
  });

  if (isLoading) {
    return <div className="loading">Loading...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  const getScoreColor = (score) => {
    if (score >= 7) return 'score-high';
    if (score >= 5) return 'score-medium';
    return 'score-low';
  };

  return (
    <div className="key-people">
      <div className="container">
        {/* Page header */}
        <header className="page-header">
          <h1>Key People Analysis</h1>
          <p>Analysis of key individuals and their roles</p>
        </header>
        
        {/* Filters */}
        <div className="filters">
          <div className="filters-grid">
            <div className="filter-item">
              <input
                type="text"
                placeholder="Search by keyword, name, etc."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="search-input"
              />
            </div>
            <div className="filter-item">
              <select
                value={selectedOrg}
                onChange={(e) => setSelectedOrg(e.target.value)}
                className="select-input"
              >
                <option value="all">All Organizations</option>
                {organizations.map(org => (
                  <option key={org} value={org}>{org}</option>
                ))}
              </select>
            </div>
            <div className="filter-item">
              <select
                value={employmentFilter}
                onChange={(e) => setEmploymentFilter(e.target.value)}
                className="select-input"
              >
                <option value="all">All Employees</option>
                <option value="current">Current Employees</option>
                <option value="former">Former Employees</option>
              </select>
            </div>
            <div className="filter-item">
              <select
                value={sortOrder}
                onChange={(e) => setSortOrder(e.target.value)}
                className="select-input"
              >
                <option value="importance_desc">Most Important</option>
                <option value="reach_desc">Most Followers</option>
              </select>
            </div>
          </div>
        </div>

        {/* Results grid */}
        <div className="people-grid">
          {sortedPeople.map((person) => {
            const scores = getScores(person);
            return (
              <div key={person.twitter_id} className="person-card">
                <div className="person-header">
                  <div className="person-info">
                    <h2>{person.name}</h2>
                    <div className="twitter-handle">@{person.twitter_handle}</div>
                  </div>
                  <a
                    href={`https://twitter.com/${person.twitter_handle}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="external-link"
                    title="Open Twitter profile"
                  >
                    <svg className="external-icon" viewBox="0 0 24 24" width="14" height="14">
                      <path d="M5 3c-1.093 0-2 .907-2 2v14c0 1.093.907 2 2 2h14c1.093 0 2-.907 2-2v-7h-2v7H5V5h7V3H5zm9 0v2h3.586l-9.293 9.293 1.414 1.414L19 6.414V10h2V3h-7z"/>
                    </svg>
                  </a>
                </div>

                <p className="person-description">{person.description}</p>

                <div className="person-tags">
                  <div className={`score-chip ${getScoreColor(scores.total)}`} 
                       title={`Importance: ${scores.importance.toFixed(1)} | Confidence: ${scores.confidence.toFixed(1)} | Reach: ${scores.followers.toFixed(1)}`}>
                    Score: {scores.total.toFixed(1)}/10
                    <div className="importance-explanation">{person.importance_explanation}</div>
                  </div>
                  {person.organization && (
                    <div className="org-chip">{person.organization}</div>
                  )}
                  <div className={`employment-chip ${person.is_current_employee ? 'current' : 'former'}`}>
                    {person.is_current_employee ? "Current Employee" : "Former Employee"}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default KeyPeople;