import React, { useState } from 'react';
import { useAuth } from './AuthContext';
import CircularProgress from '@mui/material/CircularProgress';
import SendIcon from '@mui/icons-material/Send';
import '../styles/CompanyChat.css';

const CompanyChat = ({ companyId }) => {
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const { flaskServerAddress } = useAuth();

    const sendMessage = async (message) => {
        setMessages(prev => [...prev, { type: 'user', content: message }]);
        setIsLoading(true);

        try {
            const response = await fetch(`${flaskServerAddress}company-chat/${companyId}/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    question: message,
                    strategy: 'hierarchical',
                    max_posts: 200
                }),
            });

            const data = await response.json();
            if (response.ok) {
                const formattedAnswer = {
                    type: 'assistant',
                    content: data.answer,
                    metadata: data.metadata
                };
                setMessages(prev => [...prev, formattedAnswer]);
            } else {
                setMessages(prev => [...prev, { 
                    type: 'error', 
                    content: data.error || 'Sorry, there was an error processing your request.' 
                }]);
            }
        } catch (error) {
            console.error('Error in chat:', error);
            setMessages(prev => [...prev, { 
                type: 'error', 
                content: 'Sorry, there was an error connecting to the server.' 
            }]);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSubmit = async (e) => {
        e?.preventDefault();
        if (!input.trim()) return;

        const userMessage = input.trim();
        setInput('');
        await sendMessage(userMessage);
    };

    const handleExampleClick = (question) => {
        sendMessage(question);
    };

    const renderSourceReference = (sourceId, idx, isLast) => (
        <a
            key={sourceId}
            href={`/post/${sourceId}`}
            target="_blank"
            rel="noopener noreferrer"
            className="source-reference"
            title="View source post"
        >
            <sup>{idx + 1}{!isLast && ','}&nbsp;</sup>
        </a>
    );

    const renderMessage = (message) => {
        switch (message.type) {
            case 'user':
                return <div className="message-content">{message.content}</div>;
            case 'assistant':
                let footnoteCounter = 0;
                return (
                    <div className="message-content">
                        <div className="answer-text">
                            {message.content.map((item) => (
                                <p key={footnoteCounter} className="answer-paragraph">
                                    {item.comment}
                                    {item.sources && item.sources.length > 0 && (
                                        <span className="source-references">
                                            {item.sources.map((sourceId, sourceIdx) => {
                                                footnoteCounter++;
                                                return renderSourceReference(
                                                    sourceId,
                                                    footnoteCounter - 1,
                                                    sourceIdx === item.sources.length - 1
                                                );
                                            })}
                                        </span>
                                    )}
                                </p>
                            ))}
                        </div>
                        {message.metadata && (
                            <div className="metadata">
                                <small>
                                    Processed {message.metadata.post_count || 0} posts with {message.metadata.verified_citations || 0} verified citations
                                </small>
                            </div>
                        )}
                    </div>
                );
            case 'error':
                return <div className="message-content error">{message.content}</div>;
            default:
                return null;
        }
    };

    const exampleQuestions = [
        "What are the key customer pain points mentioned?",
        "How does their product quality compare to competitors?",
        "What are the main reasons customers switch away?"
    ];

    return (
        <div className="company-chat card card-md">
            <div className="chat-messages">
                {messages.length === 0 && (
                    <div className="empty-chat">
                        <h3>Research Assistant</h3>
                        <p>Ask questions to analyze customer feedback and market insights:</p>
                        <ul>
                            {exampleQuestions.map((question, index) => (
                                <li 
                                    key={index} 
                                    onClick={() => handleExampleClick(question)}
                                    role="button"
                                    tabIndex={0}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter' || e.key === ' ') {
                                            handleExampleClick(question);
                                        }
                                    }}
                                >
                                    {question}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
                {messages.map((message, index) => (
                    <div key={index} className={`message ${message.type}`}>
                        {renderMessage(message)}
                    </div>
                ))}
                {isLoading && (
                    <div className="message assistant loading">
                        <CircularProgress size={20} />
                    </div>
                )}
            </div>
            <form onSubmit={handleSubmit} className="chat-input-form">
                <input
                    type="text"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    placeholder="Ask a question..."
                    disabled={isLoading}
                />
                <button type="submit" disabled={isLoading || !input.trim()}>
                    <SendIcon />
                </button>
            </form>
        </div>
    );
};

export default CompanyChat; 