/*
This component displays a pie chart of competitor mentions for a selected topic.
It fetches data from the API and allows filtering by time range.
*/

import React, { useState, useEffect, useMemo } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip, Legend, Sector } from 'recharts';
import TimeRangeSelector from './TimeRangeSelector';
import ProductSelector from './ProductSelector';
import { subYears, format } from 'date-fns';
import '../styles/CompetitorMentionsChart.css';
import { useAuth } from './AuthContext';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';

// Enhanced color palette for better visual appeal
const COLORS = [
    '#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8',
    '#82ca9d', '#ffc658', '#ff7300', '#a4de6c', '#d0ed57'
];

// Special color for the "Other" category
const OTHER_COLOR = '#E0E0E0';

const CompetitorMentionsChart = ({ topics }) => {
    const { flaskServerAddress } = useAuth();
    const [timeRange, setTimeRange] = useState('3y');
    const [selectedProduct, setSelectedProduct] = useState("");
    const [competitorMentions, setCompetitorMentions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [initialLoad, setInitialLoad] = useState(true);
    const [activeIndex, setActiveIndex] = useState(null);
    const [hiddenCompetitors, setHiddenCompetitors] = useState(new Set());

    // Calculate date range based on selected time range
    const dateRange = useMemo(() => {
        const now = new Date();
        let startDate;
        
        switch(timeRange) {
            case '1y':
                startDate = subYears(now, 1);
                break;
            case '3y':
                startDate = subYears(now, 3);
                break;
            case '5y':
                startDate = subYears(now, 5);
                break;
            case 'all':
            default:
                startDate = new Date(0); // Beginning of time
                break;
        }
        
        return {
            start_date: format(startDate, 'yyyy-MM-dd'),
            end_date: format(now, 'yyyy-MM-dd')
        };
    }, [timeRange]);

    // Fetch competitor mentions when the selected product or time range changes
    useEffect(() => {
        const fetchCompetitorMentions = async () => {
            if (!selectedProduct) {
                if (!initialLoad) {
                    setCompetitorMentions([]);
                }
                return;
            }

            setLoading(true);
            setError(null);

            try {
                // Log the API URL being called
                const apiUrl = `${flaskServerAddress}competitor-mentions/${selectedProduct}/`;
                console.log('Calling API endpoint:', apiUrl);
                
                // Call the new API endpoint with time range parameters
                const response = await axios.get(apiUrl, {
                    params: {
                        min_percentage: 5.0, // Minimum percentage threshold for grouping
                        start_date: dateRange.start_date,
                        end_date: dateRange.end_date
                    }
                });

                console.log('API response:', response.data);

                if (response.data.success) {
                    setCompetitorMentions(response.data.data);
                } else {
                    console.error('API returned error:', response.data.error);
                    setError(response.data.error || 'Failed to fetch competitor mentions');
                    setCompetitorMentions([]);
                }
            } catch (err) {
                console.error('Error fetching competitor mentions:', err);
                // Log more detailed error information
                if (err.response) {
                    console.error('Error response data:', err.response.data);
                    console.error('Error response status:', err.response.status);
                    console.error('Error response headers:', err.response.headers);
                } else if (err.request) {
                    console.error('Error request:', err.request);
                } else {
                    console.error('Error message:', err.message);
                }
                setError('Failed to fetch competitor mentions');
                setCompetitorMentions([]);
            } finally {
                setLoading(false);
                setInitialLoad(false);
            }
        };

        fetchCompetitorMentions();
    }, [selectedProduct, flaskServerAddress, initialLoad, dateRange, timeRange]);

    // Calculate visible competitor mentions and adjust percentages
    const visibleCompetitorMentions = useMemo(() => {
        if (!competitorMentions || competitorMentions.length === 0) return [];
        
        // Filter out hidden competitors
        const visibleMentions = competitorMentions.filter(
            competitor => !hiddenCompetitors.has(competitor.competitor_name)
        );

        // Recalculate total mentions for visible competitors
        const visibleTotal = visibleMentions.reduce((sum, item) => sum + item.mention_count, 0);

        // Recalculate percentages based on visible total
        return visibleMentions.map(mention => ({
            ...mention,
            mention_percentage: (mention.mention_count / visibleTotal) * 100
        }));
    }, [competitorMentions, hiddenCompetitors]);

    // Sort competitor mentions to ensure "Other" is always last
    const sortedCompetitorMentions = useMemo(() => {
        if (!visibleCompetitorMentions || visibleCompetitorMentions.length === 0) return [];
        
        return [...visibleCompetitorMentions].sort((a, b) => {
            if (a.competitor_name === 'Other') return 1;
            if (b.competitor_name === 'Other') return -1;
            return b.mention_count - a.mention_count;
        });
    }, [visibleCompetitorMentions]);

    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            const data = payload[0].payload;
            return (
                <div className="custom-tooltip">
                    <p className="competitor-name">{data.competitor_name}</p>
                    <p className="mention-count">{data.mention_count} mentions</p>
                    <p className="mention-percentage">
                        {data.mention_percentage.toFixed(1)}% of all mentions
                    </p>
                </div>
            );
        }
        return null;
    };

    // Handle pie sector hover
    const onPieEnter = (_, index) => {
        setActiveIndex(index);
    };

    const onPieLeave = () => {
        setActiveIndex(null);
    };

    // Active shape rendering for hover effect
    const renderActiveShape = (props) => {
        const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } = props;
        
        return (
            <g>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius + 5}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}
                />
            </g>
        );
    };

    // Render the percentage label inside the pie chart
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, name, value }) => {
        // Only show labels for sections with enough space (more than 5%)
        if (percent < 0.05) return null;
        
        // For the "Other" category, don't show a label
        if (name === 'Other') return null;

        const RADIAN = Math.PI / 180;
        const radius = innerRadius + (outerRadius - innerRadius) * 0.6;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text 
                x={x} 
                y={y} 
                fill="white" 
                textAnchor="middle" 
                dominantBaseline="central"
                fontWeight="bold"
                fontSize={14}
                stroke="#000"
                strokeWidth={0.5}
                paintOrder="stroke"
                aria-label={`${name}: ${(percent * 100).toFixed(0)}% (${value} mentions)`}
            >
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    // Handle legend item hover
    const handleLegendMouseEnter = (index) => {
        setActiveIndex(index);
    };

    const handleLegendMouseLeave = () => {
        setActiveIndex(null);
    };

    // Toggle competitor visibility
    const handleLegendClick = (competitor) => {
        setHiddenCompetitors(prev => {
            const newHidden = new Set(prev);
            if (newHidden.has(competitor)) {
                newHidden.delete(competitor);
            } else {
                newHidden.add(competitor);
            }
            return newHidden;
        });
    };

    // Legend item component with active state and click handler
    const LegendItem = ({ entry, index }) => {
        const isActive = index === activeIndex;
        const isHidden = hiddenCompetitors.has(entry.competitor_name);
        
        return (
            <div 
                key={`legend-item-${index}`} 
                className={`legend-item ${isActive ? 'legend-item-active' : ''} ${isHidden ? 'legend-item-hidden' : ''}`}
                role="button"
                tabIndex={0}
                onClick={() => handleLegendClick(entry.competitor_name)}
                onKeyPress={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                        handleLegendClick(entry.competitor_name);
                    }
                }}
                onMouseEnter={() => handleLegendMouseEnter(index)}
                onMouseLeave={handleLegendMouseLeave}
                style={{ cursor: 'pointer' }}
            >
                <div 
                    className={`legend-color ${entry.competitor_name === 'Other' ? 'legend-color-other' : ''}`}
                    style={{ 
                        backgroundColor: entry.competitor_name === 'Other' ? 
                            OTHER_COLOR : COLORS[index % COLORS.length],
                        opacity: isHidden ? 0.3 : 1
                    }}
                    aria-hidden="true"
                />
                <div className="legend-details">
                    <span className="legend-text" style={{ opacity: isHidden ? 0.5 : 1 }}>
                        {entry.competitor_name}
                    </span>
                    <span className="legend-percentage" style={{ opacity: isHidden ? 0.5 : 1 }}>
                        {entry.mention_percentage.toFixed(1)}% ({entry.mention_count})
                    </span>
                </div>
            </div>
        );
    };

    // Update totalMentions to use visible mentions only
    const totalMentions = useMemo(() => {
        return visibleCompetitorMentions.reduce((sum, item) => sum + item.mention_count, 0);
    }, [visibleCompetitorMentions]);

    // Log when topics change to understand the structure
    useEffect(() => {
        console.log('Topics structure:', topics);
        if (topics?.[0]) {
            console.log('First topic:', topics[0]);
        }
    }, [topics]);

    // Log when selectedProduct changes
    useEffect(() => {
        console.log('Selected product changed to:', selectedProduct);
        // Find the selected topic to verify the structure
        const selectedTopic = topics?.find(t => t.id === selectedProduct);
        console.log('Selected topic:', selectedTopic);
    }, [selectedProduct, topics]);

    // Determine what to render based on state
    const renderContent = () => {
        if (loading) {
            return (
                <div className="loading-container">
                    <CircularProgress size={60} thickness={4} />
                    <p className="loading-text">Loading competitor mentions...</p>
                </div>
            );
        }

        if (error) {
            return (
                <div className="error-message">
                    <p>{error}</p>
                </div>
            );
        }

        if (!selectedProduct) {
            return (
                <div className="no-data-message">
                    Please select a topic to view competitor mentions.
                </div>
            );
        }

        if (competitorMentions.length === 0 && !initialLoad) {
            return (
                <div className="no-data-message">
                    No competitor mentions found for the selected topic.
                </div>
            );
        }
        
        return (
            <div className="chart-container">
                <div className="chart-with-legend">
                    {/* Chart Section */}
                    <div className="pie-chart-container">
                        <PieChart width={400} height={400} role="img" aria-label="Competitor mentions pie chart">
                            {/* Donut chart with inner radius */}
                            <Pie
                                data={sortedCompetitorMentions}
                                cx={200}
                                cy={200}
                                labelLine={false}
                                outerRadius={150}
                                innerRadius={70} 
                                fill="#8884d8"
                                dataKey="mention_count"
                                nameKey="competitor_name"
                                label={renderCustomizedLabel}
                                paddingAngle={1}
                                activeIndex={activeIndex}
                                activeShape={renderActiveShape}
                                onMouseEnter={onPieEnter}
                                onMouseLeave={onPieLeave}
                            >
                                {sortedCompetitorMentions.map((entry, index) => (
                                    <Cell 
                                        key={`cell-${index}`} 
                                        fill={entry.competitor_name === 'Other' ? OTHER_COLOR : COLORS[index % COLORS.length]} 
                                        stroke="#fff"
                                        strokeWidth={1}
                                    />
                                ))}
                            </Pie>
                        </PieChart>
                        
                        {/* Total Mentions Label */}
                        {totalMentions > 0 && (
                            <div className="chart-center-label">
                                <div className="total-mentions">{totalMentions}</div>
                                <div className="total-mentions-label">Total Mentions</div>
                            </div>
                        )}
                    </div>
                    
                    {/* Legend */}
                    <div className="custom-legend" role="list" aria-label="Chart legend">
                        {sortedCompetitorMentions.map((entry, index) => (
                            <LegendItem 
                                key={`legend-item-${index}`}
                                entry={entry} 
                                index={index} 
                            />
                        ))}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="competitor-mentions-container">
            <style>
                {`
                    .legend-item-hidden .legend-color {
                        opacity: 0.3;
                    }
                    .legend-item-hidden .legend-details {
                        opacity: 0.5;
                    }
                    .legend-item {
                        transition: opacity 0.2s ease;
                    }
                `}
            </style>
            <div className="controls">
                {topics && topics.length > 0 && (
                    <ProductSelector
                        products={topics}
                        selectedProduct={selectedProduct}
                        onProductChange={setSelectedProduct}
                        className="product-selector"
                        hideAllOption={true}
                    />
                )}
                <TimeRangeSelector
                    timeRange={timeRange}
                    onTimeRangeChange={setTimeRange}
                    periods={['1y', '3y', '5y', 'all']}
                    aria-label="Select time range"
                />
            </div>

            {renderContent()}
        </div>
    );
};

export default CompetitorMentionsChart; 