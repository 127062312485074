/**
 * bayesianNps.js
 *
 * Provides a Bayesian approach to estimate monthly Net Promoter Scores (NPS)
 * from 0–10 ratings. Uses a Dirichlet-multinomial model with optional
 * discounting to partially pool historical data.
 *
 * USAGE (high-level):
 *
 *   1. Convert your raw ratings (time, rating) into monthly rating counts
 *      for each of the 11 possible scores (0 through 10).
 *   2. Pass that into computeBayesianMonthlyNPS with:
 *        - priorAlpha: your global Dirichlet prior parameters (11-length array)
 *        - discountFactor: how much you revert back to prior each month
 *        - samples: number of random draws to estimate credible intervals
 *   3. The function returns an array of results with:
 *        { month, meanNPS, lowerCI, upperCI, count, posteriorAlpha }
 *
 *   4. You can then display these NPS estimates on a chart or table.
 */

// -------------------------------------
// NPS Configuration - Centralized parameters
// -------------------------------------
export const NPS_CONFIG = {
  // Thresholds for NPS calculation
  PROMOTER_THRESHOLD: 6,  // Ratings > 6 are promoters (7-10)
  DETRACTOR_THRESHOLD: 4, // Ratings <= 4 are detractors (0-4)
  
  // Default prior parameters for each rating (0-10)
  // These values represent the "prior belief" about the distribution of ratings
  DEFAULT_PRIOR_ALPHA: [
    1.0, // rating=0
    1.0, // rating=1
    1.0, // rating=2
    1.0, // rating=3
    1.0, // rating=4

    10.0, // rating=5
    10.0, // rating=6

    8.0, // rating=7
    8.0, // rating=8
    5.0, // rating=9
    3.0  // rating=10
  ],
  
  // Scalar to adjust the strength of the prior
  // Lower values = weaker prior (data dominates faster)
  // Higher values = stronger prior (need more data to overcome prior)
  DEFAULT_PRIOR_ALPHA_SCALAR: 0.75,
  
  // Default discount factor for time series smoothing
  DEFAULT_DISCOUNT_FACTOR: 0.05,
  
  // Default number of samples for credible intervals
  DEFAULT_SAMPLES: 1000
};

// -------------------------------------
// 1. Helper: Sample from Gamma
//    (Dirichlet is basically sampling multiple Gammas and normalizing)
// -------------------------------------
function gammaSample(alpha) {
    // Simple algorithm using Marsaglia's method or use a library if you prefer.
    // This is *not* cryptographically secure—just standard random for stats.
    if (alpha < 1) {
      // Use Johnk's transformation
      const u = Math.random();
      return gammaSample(1 + alpha) * Math.pow(u, 1 / alpha);
    }
    // alpha >= 1
    const d = alpha - 1/3;
    const c = 1 / Math.sqrt(9*d);
    let x, v, u;
    while(true) {
      do {
        x = normalSample();
        v = 1 + c*x;
      } while (v <= 0);
      v = v*v*v;
      u = Math.random();
      if (u < 1 - 0.0331*(x*x)*(x*x) || Math.log(u) < 0.5*x*x + d*(1 - v + Math.log(v))) {
        return d*v;
      }
    }
  }
  
  function normalSample() {
    // Box-Muller transform
    const u1 = Math.random();
    const u2 = Math.random();
    const r = Math.sqrt(-2.0 * Math.log(u1));
    const theta = 2.0 * Math.PI * u2;
    return r * Math.cos(theta);
  }
  
  // -------------------------------------
  // 2. Helper: Sample from Dirichlet
  // -------------------------------------
  function dirichletSample(alphaArray) {
    // alphaArray = [alpha0, alpha1, ..., alpha10]
    // Return an array [theta0, ..., theta10] that sums to 1.
    const dim = alphaArray.length;
    const gammas = new Array(dim);
    let sumGamma = 0;
    for (let i = 0; i < dim; i++) {
      gammas[i] = gammaSample(alphaArray[i]);
      sumGamma += gammas[i];
    }
    // Normalize
    return gammas.map(g => g / sumGamma);
  }
  
  /**
   * Summarize one Dirichlet posterior sample into an NPS value:
   *   NPS = p(promoter) - p(detractor)
   *   where promoter = ratings > PROMOTER_THRESHOLD
   *   and detractor = ratings <= DETRACTOR_THRESHOLD
   */
  function npsFromTheta(thetaArray) {
    // Use the thresholds from the config
    const { PROMOTER_THRESHOLD, DETRACTOR_THRESHOLD } = NPS_CONFIG;
    
    // Calculate promoter probability (ratings > PROMOTER_THRESHOLD)
    const promoterProb = thetaArray
      .slice(PROMOTER_THRESHOLD + 1)
      .reduce((acc, val) => acc + val, 0);
    
    // Calculate detractor probability (ratings <= DETRACTOR_THRESHOLD)
    const detractorProb = thetaArray
      .slice(0, DETRACTOR_THRESHOLD + 1)
      .reduce((acc, val) => acc + val, 0);
    
    return 100 * (promoterProb - detractorProb);
  }
  
  // -------------------------------------
  // 3. Main Function
  // -------------------------------------
  
  // Helper: Generate array of months between start and end dates
  function generateMonthRange(startMonth, endMonth) {
    const [startYear, startMo] = startMonth.split('-').map(n => parseInt(n));
    const [endYear, endMo] = endMonth.split('-').map(n => parseInt(n));
    
    const months = [];
    let currentYear = startYear;
    let currentMonth = startMo;
    
    while (currentYear < endYear || (currentYear === endYear && currentMonth <= endMo)) {
      months.push(`${currentYear}-${String(currentMonth).padStart(2, '0')}`);
      currentMonth++;
      if (currentMonth > 12) {
        currentMonth = 1;
        currentYear++;
      }
    }
    
    return months;
  }
  
  // Helper: Get current month in YYYY-MM format
  function getCurrentMonth() {
    const now = new Date();
    return `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}`;
  }
  
  /**
   * @param {Object[]} monthlyData - Array of objects containing:
   *        { month: "YYYY-MM", counts: [n0, n1, ..., n10] } 
   *        i.e. rating counts for each of the 11 rating values (0..10).
   *        Must be in CHRONOLOGICAL order (earliest to latest).
   *
   * @param {Number[]} [globalPriorAlpha] - 11-length array with your global prior.
   *                                        Defaults to NPS_CONFIG.DEFAULT_PRIOR_ALPHA.
   *
   * @param {Number} [discountFactor] - 0 <= discountFactor <= 1
   *        If 0, we carry over the previous month's posterior with full weight.
   *        If 1, we ignore the previous posterior and revert to global prior each month.
   *        Defaults to NPS_CONFIG.DEFAULT_DISCOUNT_FACTOR.
   *
   * @param {Number} [samples] - how many random draws to use for the credible interval.
   *        Defaults to NPS_CONFIG.DEFAULT_SAMPLES.
   *
   * @returns {Array} an array of:
   *        {
   *          month: string (YYYY-MM),
   *          meanNPS: number,
   *          lowerCI: number,
   *          upperCI: number,
   *          count: number (sum of rating counts in that month),
   *          posteriorAlpha: number[] (the Dirichlet posterior parameters for that month),
   *          isForwardFilled: boolean (whether this month was filled with previous data)
   *        }
   *
   * This is a "forward-only" method—no future data is used in earlier months' estimates.
   * Missing months will be filled with the last known values, including up to the current month.
   */
  export function computeBayesianMonthlyNPS(
    monthlyData, 
    globalPriorAlpha = NPS_CONFIG.DEFAULT_PRIOR_ALPHA, 
    discountFactor = NPS_CONFIG.DEFAULT_DISCOUNT_FACTOR, 
    samples = NPS_CONFIG.DEFAULT_SAMPLES
  ) {
    if (!monthlyData.length) return [];

    // 1) Generate complete month range up to current month
    const startMonth = monthlyData[0].month;
    const currentMonth = getCurrentMonth();
    const endMonth = monthlyData[monthlyData.length - 1].month;
    
    // If last data point is before current month, extend to current month
    const effectiveEndMonth = endMonth < currentMonth ? currentMonth : endMonth;
    const allMonths = generateMonthRange(startMonth, effectiveEndMonth);
    
    // Create a map of existing data points
    const dataMap = new Map(monthlyData.map(d => [d.month, d]));
    
    // 2) We track an evolving priorAlpha each month
    const K = globalPriorAlpha.length; // should be 11
    let prevPosteriorAlpha = globalPriorAlpha.slice();
    let lastResult = null;
    
    const results = [];

    allMonths.forEach(month => {
      const dataPoint = dataMap.get(month);
      
      if (dataPoint) {
        // Process actual data point
        const { counts } = dataPoint;
        if (!counts || counts.length !== K) {
          throw new Error(`counts must be array of length ${K}. Found: ${counts}`);
        }

        // This month's prior = mixture of the global prior & last month's posterior
        let priorAlphaM = new Array(K);
        for (let i = 0; i < K; i++) {
          priorAlphaM[i] = discountFactor * globalPriorAlpha[i] 
                           + (1 - discountFactor) * prevPosteriorAlpha[i];
        }

        // Posterior = priorAlphaM + observed counts
        let posteriorAlpha = new Array(K);
        let sumCounts = 0;
        for (let i = 0; i < K; i++) {
          posteriorAlpha[i] = priorAlphaM[i] + counts[i];
          sumCounts += counts[i];
        }

        // Sample from the Dirichlet to get credible intervals for NPS
        const npsSamples = [];
        for (let s = 0; s < samples; s++) {
          const thetaS = dirichletSample(posteriorAlpha);
          npsSamples.push(npsFromTheta(thetaS));
        }
        npsSamples.sort((a, b) => a - b);

        const meanNPS = npsSamples.reduce((acc, val) => acc + val, 0) / samples;
        const rawLowerCI = npsSamples[Math.floor(0.025 * samples)];
        const rawUpperCI = npsSamples[Math.floor(0.975 * samples)];

        // Inflation factor function - increases interval width for small sample sizes
        function inflationFactor(n) {
          const kappa = 4;  // Controls strength of inflation for small n
          const gamma = 8;  // Controls how quickly inflation drops off
          return 1 + kappa / (n + gamma);
        }

        // Compute inflated bounds while keeping mean centered
        const mid = meanNPS;
        const halfWidthLower = mid - rawLowerCI;
        const halfWidthUpper = rawUpperCI - mid;
        const factor = inflationFactor(sumCounts);

        const lowerCI = mid - factor * halfWidthLower;
        const upperCI = mid + factor * halfWidthUpper;

        lastResult = {
          month,
          meanNPS,
          lowerCI,
          upperCI,
          count: sumCounts,
          posteriorAlpha,
          isForwardFilled: false
        };
        
        results.push(lastResult);
        prevPosteriorAlpha = posteriorAlpha;
      } else if (lastResult) {
        // Forward fill with last known values
        results.push({
          ...lastResult,
          month,
          isForwardFilled: true
        });
      }
    });

    return results;
  }
  