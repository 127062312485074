import React, { useState, useEffect } from 'react';
import { Line, Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import ProductSelector from './ProductSelector';
import TimeRangeSelector from './TimeRangeSelector';
import Toggle from './Toggle';
import PreferenceBar from './PreferenceBar';
import { computeBayesianMonthlyNPS, NPS_CONFIG } from '../functions/bayesianNPS';
import '../styles/NPSComparisonChart.css';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const CHART_COLORS = {
    main: {
        line: 'rgb(47, 71, 184)',  // dark blue for main product
        fill: 'rgba(47, 71, 184, 0.1)'
    },
    competitors: [
        { line: 'rgb(75, 192, 192)', fill: 'rgba(75, 192, 192, 0.1)' },     // cyan blue
        { line: 'rgb(138, 43, 226)', fill: 'rgba(138, 43, 226, 0.1)' },    // Purple
        { line: 'rgb(134, 0, 46)', fill: 'rgba(134, 0, 46, 0.1)' },     // dark red
        { line: 'rgb(255, 99, 71)', fill: 'rgba(255, 99, 71, 0.1)' },     // Tomato red
        { line: 'rgb(50, 205, 50)', fill: 'rgba(50, 205, 50, 0.1)' },     // Lime green
        { line: 'rgb(255, 140, 0)', fill: 'rgba(255, 140, 0, 0.1)' },     // Dark orange
    ]
};

const NPSComparisonChart = ({ topics, stat_name = 'NPS' }) => {
    const [selectedProduct, setSelectedProduct] = useState("");
    const [timeRange, setTimeRange] = useState('3y');
    const [chartData, setChartData] = useState(null);
    const [summaryStats, setSummaryStats] = useState({});
    const [showRawScores, setShowRawScores] = useState(false);
    const [comparisonData, setComparisonData] = useState(null);

    const calculateDensity = (posts) => {
        const histogram = new Array(11).fill(0); // 0-10 ratings
        let total = 0;
        let count = 0;

        posts.forEach(post => {
            const assessment = post.post_assessments?.find(a => a?.type === stat_name);
            if (assessment?.rating !== null && assessment?.rating !== undefined) {
                histogram[Math.round(assessment.rating)]++;
                total += assessment.rating;
                count++;
            }
        });

        // Convert to percentages
        const density = histogram.map(value => count > 0 ? (value / count) * 100 : 0);

        return {
            density,
            mean: count > 0 ? total / count : 0,
            count,
            histogram
        };
    };

    const calculateNPSBreakdown = (posts) => {
        let promoters = 0;
        let detractors = 0;
        let neutrals = 0;
        let total = 0;

        posts.forEach(post => {
            const assessment = post.post_assessments?.find(a => a?.type === stat_name);
            if (assessment?.rating !== null && assessment?.rating !== undefined) {
                const rating = assessment.rating;
                if (rating > NPS_CONFIG.PROMOTER_THRESHOLD) promoters++;
                else if (rating <= NPS_CONFIG.DETRACTOR_THRESHOLD) detractors++;
                else neutrals++;
                total++;
            }
        });

        return {
            promoters: total > 0 ? (promoters / total) * 100 : 0,
            detractors: total > 0 ? (detractors / total) * 100 : 0,
            neutrals: total > 0 ? (neutrals / total) * 100 : 0,
            total
        };
    };

    const getStartDateFromTimeRange = (range) => {
        const now = new Date();
        const years = parseInt(range);
        if (isNaN(years)) return null;
        return new Date(now.setFullYear(now.getFullYear() - years));
    };

    const filterPostsByDate = (posts, startDate) => {
        if (!startDate) return posts;
        return posts.filter(post => {
            const postDate = new Date(post.time * 1000);
            return postDate >= startDate;
        });
    };

    const calculateBayesianNPS = (posts) => {
        // Convert posts to monthly data format expected by computeBayesianMonthlyNPS
        const monthlyData = [{
            month: '2024-01', // We only need one month since we want overall NPS
            counts: new Array(11).fill(0)
        }];

        posts.forEach(post => {
            const assessment = post.post_assessments?.find(a => a?.type === stat_name);
            if (assessment?.rating !== null && assessment?.rating !== undefined) {
                monthlyData[0].counts[Math.round(assessment.rating)]++;
            }
        });

        const results = computeBayesianMonthlyNPS(
            monthlyData,
            NPS_CONFIG.DEFAULT_PRIOR_ALPHA.map(alpha => alpha * NPS_CONFIG.DEFAULT_PRIOR_ALPHA_SCALAR),
            NPS_CONFIG.DEFAULT_DISCOUNT_FACTOR,
            NPS_CONFIG.DEFAULT_SAMPLES
        );

        return results[0]?.meanNPS || 0;
    };

    useEffect(() => {
        if (!topics || !selectedProduct) return;

        const startDate = getStartDateFromTimeRange(timeRange);
        const selectedTopic = topics.find(topic => topic.id === selectedProduct);
        
        if (!selectedTopic) return;

        // Process main product data
        const mainPosts = filterPostsByDate(selectedTopic.posts || [], startDate);
        const mainStats = calculateDensity(mainPosts);
        const mainNPSBreakdown = calculateNPSBreakdown(mainPosts);
        const mainBayesianNPS = calculateBayesianNPS(mainPosts);

        // Process competitor data
        const competitorStats = {};
        const competitorBreakdowns = {};
        const competitorBayesianNPS = {};
        if (selectedTopic.competitors) {
            selectedTopic.competitors.forEach(competitor => {
                if (!competitor?.posts) return;
                const competitorPosts = filterPostsByDate(competitor.posts, startDate);
                competitorStats[competitor.topic] = calculateDensity(competitorPosts);
                competitorBreakdowns[competitor.topic] = calculateNPSBreakdown(competitorPosts);
                competitorBayesianNPS[competitor.topic] = calculateBayesianNPS(competitorPosts);
            });
        }

        // Prepare density chart data
        const datasets = [
            {
                label: selectedTopic.topic,
                data: mainStats.density,
                backgroundColor: CHART_COLORS.main.fill,
                borderColor: CHART_COLORS.main.line,
                borderWidth: 2,
                tension: 0.4,
                fill: true,
                order: 1,
                pointRadius: 0,
                sampleSize: mainStats.count
            }
        ];

        Object.entries(competitorStats).forEach(([name, stats], index) => {
            const colorSet = CHART_COLORS.competitors[index % CHART_COLORS.competitors.length];
            datasets.push({
                label: name,
                data: stats.density,
                backgroundColor: colorSet.fill,
                borderColor: colorSet.line,
                borderWidth: 2,
                tension: 0.4,
                fill: true,
                order: index + 2,
                pointRadius: 0,
                sampleSize: stats.count
            });
        });

        setChartData({
            labels: Array.from({ length: 11 }, (_, i) => i.toString()),
            datasets
        });

        // Prepare comparison data
        const allCompanies = [
            {
                name: selectedTopic.topic,
                stats: mainStats,
                breakdown: mainNPSBreakdown,
                bayesianNPS: mainBayesianNPS,
                isMain: true
            },
            ...Object.entries(competitorStats).map(([name, stats]) => ({
                name,
                stats,
                breakdown: competitorBreakdowns[name],
                bayesianNPS: competitorBayesianNPS[name],
                isMain: false
            }))
        ];

        // Sort by Bayesian NPS or raw score
        allCompanies.sort((a, b) => b.bayesianNPS - a.bayesianNPS);

        setComparisonData(allCompanies);

        // Update summary stats
        setSummaryStats({
            main: {
                name: selectedTopic.topic,
                ...mainStats,
                breakdown: mainNPSBreakdown,
                bayesianNPS: mainBayesianNPS
            },
            competitors: Object.entries(competitorStats).map(([name, stats]) => ({
                name,
                ...stats,
                breakdown: competitorBreakdowns[name],
                bayesianNPS: competitorBayesianNPS[name]
            }))
        });

    }, [topics, selectedProduct, timeRange, stat_name]);

    const chartOptions = {
        responsive: true,
        scales: {
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Percentage of Reviews',
                    font: {
                        size: 14,
                        weight: 'bold'
                    }
                },
                ticks: {
                    callback: function(value) {
                        return value + '%';
                    }
                },
                grid: {
                    color: 'rgba(0, 0, 0, 0.1)',  // Lighter grid lines
                    drawBorder: false
                }
            },
            x: {
                title: {
                    display: true,
                    text: 'NPS Rating',
                    font: {
                        size: 14,
                        weight: 'bold'
                    }
                },
                grid: {
                    color: 'rgba(0, 0, 0, 0.1)',  // Lighter grid lines
                    drawBorder: false
                }
            }
        },
        plugins: {
            legend: {
                position: 'top',
                align: 'end'
            },
            tooltip: {
                callbacks: {
                    label: function(context) {
                        const label = context.dataset.label || '';
                        const value = context.parsed.y.toFixed(1);
                        const sampleSize = context.dataset.sampleSize;
                        return [
                            `${label}: ${value}%`,
                            `Sample Size: ${sampleSize}`
                        ];
                    }
                }
            }
        }
    };

    const comparisonChartData = {
        labels: comparisonData?.map(c => c.name) || [],
        datasets: [{
            label: showRawScores ? 'Average Rating' : 'Bayesian NPS',
            data: comparisonData?.map(c => showRawScores ? c.stats.mean : c.bayesianNPS) || [],
            backgroundColor: comparisonData?.map(c => 
                c.isMain ? CHART_COLORS.main.line : CHART_COLORS.competitors[0].line
            ) || [],
            borderWidth: 1
        }]
    };

    const comparisonChartOptions = {
        responsive: true,
        scales: {
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: showRawScores ? 'Average Rating' : 'Net Promoter Score',
                    font: {
                        size: 14,
                        weight: 'bold'
                    }
                }
            },
            x: {
                title: {
                    display: true,
                    text: 'Companies',
                    font: {
                        size: 14,
                        weight: 'bold'
                    }
                }
            }
        },
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                callbacks: {
                    label: function(context) {
                        const company = comparisonData[context.dataIndex];
                        const value = context.parsed.y.toFixed(1);
                        return [
                            `${showRawScores ? 'Average Rating' : 'NPS'}: ${value}`,
                            `Sample Size: ${company.stats.count}`
                        ];
                    }
                }
            }
        }
    };

    const handleTimeRangeChange = (newRange) => {
        setTimeRange(newRange);
    };

    return (
        <div className="nps-comparison-chart">
            <div className="chart-controls">
                <ProductSelector
                    products={topics}
                    selectedProduct={selectedProduct}
                    onProductChange={setSelectedProduct}
                />
                <TimeRangeSelector
                    timeRange={timeRange}
                    onTimeRangeChange={handleTimeRangeChange}
                />
            </div>

            {chartData && (
                <>
                    <div className="chart-wrapper">
                        <Line data={chartData} options={chartOptions} />
                    </div>
                    
                    <div className="comparison-section">
                        <div className="section-header">
                            <h3>Comparative Analysis</h3>
                            <Toggle
                                label="Show Raw Scores"
                                checked={showRawScores}
                                onChange={setShowRawScores}
                            />
                        </div>
                        <div className="comparison-chart-wrapper">
                            <Bar data={comparisonChartData} options={comparisonChartOptions} />
                        </div>
                        
                        <div className="nps-breakdown-grid">
                            {comparisonData?.map((company) => (
                                <div 
                                    key={company.name} 
                                    className={`nps-breakdown-card ${company.isMain ? 'main-product' : 'competitor'}`}
                                >
                                    <h4>{company.name}</h4>
                                    <PreferenceBar
                                        promoters={company.breakdown.promoters}
                                        neutrals={company.breakdown.neutrals}
                                        detractors={company.breakdown.detractors}
                                    />
                                    <div className="breakdown-stats">
                                        <p>Bayesian NPS: {company.bayesianNPS.toFixed(1)}</p>
                                        <p>Raw Score: {company.stats.mean.toFixed(1)}</p>
                                        <p>Sample Size: {company.stats.count}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default NPSComparisonChart; 