import React from 'react';
import '../styles/PreferenceBar.css';

const PreferenceBar = ({ promoters, neutrals, detractors, showPercentages = true }) => {
    return (
        <div className="preference-bar-container">
            <div className="preference-bar">
                <div 
                    className="preference-segment promoters" 
                    style={{ width: `${promoters}%` }}
                />
                <div 
                    className="preference-segment neutrals" 
                    style={{ width: `${neutrals}%` }}
                />
                <div 
                    className="preference-segment detractors" 
                    style={{ width: `${detractors}%` }}
                />
            </div>
            {showPercentages && (
                <div className="percentage-labels">
                    <span className="promoters">{promoters.toFixed(1)}%</span>
                    <span className="neutrals">{neutrals.toFixed(1)}%</span>
                    <span className="detractors">{detractors.toFixed(1)}%</span>
                </div>
            )}
        </div>
    );
};

export default PreferenceBar; 