import React from 'react';
import BusinessIcon from '@mui/icons-material/Business';

const SearchPreviewResultCompany = ({ company, isSelected, onClick }) => {
    const renderLogo = () => {
        if (!company.logo) {
            return <BusinessIcon style={{ fontSize: '16px' }} />;
        }

        // Check if the logo is an SVG string
        if (typeof company.logo === 'string' && company.logo.trim().startsWith('<svg')) {
            return (
                <div 
                    className="company-logo-svg"
                    dangerouslySetInnerHTML={{ __html: company.logo }}
                    style={{ width: '20px', height: '20px' }}
                />
            );
        }

        // If it's a URL, render as image
        return (
            <img 
                src={company.logo} 
                alt={company.name} 
                style={{ width: '20px', height: '20px', objectFit: 'contain' }}
            />
        );
    };

    return (
        <div 
            className={`search-preview-item ${isSelected ? 'selected' : ''}`}
            onClick={() => onClick?.(company)}
        >
            <div className="company-icon">
                {renderLogo()}
            </div>
            <div className="company-info">
                <div className="company-name">
                    {company.name}
                    {company.ticker && (
                        <span className="company-ticker-badge">{company.ticker}</span>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SearchPreviewResultCompany;
