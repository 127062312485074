import React, { useMemo, useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import NoDataMessage from './NoDataMessage';
import { Link, useNavigate } from 'react-router-dom';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ProductSelector from './ProductSelector';
import FilteredPostsDisplay from './FilteredPostsDisplay';

const MIN_MENTIONS = 10;
const MAX_COMPETITORS = 5;

// Rating thresholds configuration
const RATING_THRESHOLDS = {
    POSITIVE: 2,    // Ratings > 2 are positive
    NEGATIVE: -2,   // Ratings < -2 are negative
    // Ratings between NEGATIVE and POSITIVE are neutral
};

// Helper function to categorize a rating
const categorizeRating = (rating) => {
    if (rating > RATING_THRESHOLDS.POSITIVE) return 'positive';
    if (rating < RATING_THRESHOLDS.NEGATIVE) return 'negative';
    return 'neutral';
};

// Helper function to format summary text with bullet points
const formatSummaryText = (summary) => {
    if (!summary) return null;
    const [mainText, bulletPoints] = summary.split('Key Takeaways:');
    if (!bulletPoints) return summary;

    return (
        <>
            <p>{mainText.trim()}</p>
            <h4>Key Takeaways:</h4>
            <ul>
                {bulletPoints.split('•')
                    .map(point => point.trim())
                    .filter(point => point.length > 0)
                    .map((point, index) => (
                        <li key={index}>{point}</li>
                    ))
                }
            </ul>
        </>
    );
};

// Helper function to adjust percentages to sum to 100%
const adjustPercentages = (positive, neutral, negative, total) => {
    // Calculate raw percentages
    const posPercent = Math.round((positive / total) * 100);
    const neutPercent = Math.round((neutral / total) * 100);
    const negPercent = Math.round((negative / total) * 100);
    
    // Calculate exact (unrounded) percentages
    const exactPos = (positive / total) * 100;
    const exactNeut = (neutral / total) * 100;
    const exactNeg = (negative / total) * 100;
    
    // Calculate rounding differences
    const posDiff = Math.abs(exactPos - posPercent);
    const neutDiff = Math.abs(exactNeut - neutPercent);
    const negDiff = Math.abs(exactNeg - negPercent);
    
    // Calculate total and determine which segment gets the adjustment
    const totalPercentage = posPercent + neutPercent + negPercent;
    const diff = 100 - totalPercentage;
    
    // Determine which segment was closest to being rounded up
    const diffs = [
        { segment: 'pos', diff: posDiff },
        { segment: 'neut', diff: neutDiff },
        { segment: 'neg', diff: negDiff }
    ].sort((a, b) => b.diff - a.diff);
    
    return {
        display: { pos: posPercent, neut: neutPercent, neg: negPercent },
        adjusted: {
            pos: posPercent + (diffs[0].segment === 'pos' ? diff : 0),
            neut: neutPercent + (diffs[0].segment === 'neut' ? diff : 0),
            neg: negPercent + (diffs[0].segment === 'neg' ? diff : 0)
        }
    };
};

const CompetitiveSummary = ({ 
    competitor, 
    stats, 
    expanded, 
    onToggle, 
    companyId, 
    summary,
    competitorDetails,
    companyName,
    topicName,
    posts,
    onSegmentClick
}) => {
    const { total, positive, negative, neutral, churnFrom, churnTo } = stats;
    
    const percentages = adjustPercentages(positive, neutral, negative, total);
    const netChurn = (churnTo || 0) - (churnFrom || 0);
    const hasChurnData = churnFrom > 0 || churnTo > 0;

    const navigate = useNavigate();

    const handleHeadToHeadClick = (e) => {
        e.stopPropagation();
        navigate(`/company/${companyId}?section=head-to-head`);
    };

    const handleChurnClick = (e) => {
        e.stopPropagation();
        navigate(`/company/${companyId}?section=comp-losses`);
    };

    // Format the summary text to properly handle bullet points
    const formattedSummary = useMemo(() => formatSummaryText(summary), [summary]);

    return (
        <div className={`card-sm card competitor-card${expanded ? 'expanded' : ''}`}>
            <div className="competitor-header" onClick={onToggle}>
                <div className="competitor-title">
                    <div className="competitor-name">{competitor}</div>
                    <div className="competitor-actions">
                        <Link 
                            to={`/company/${companyId}?section=head-to-head`}
                            className="head-to-head-link"
                            onClick={(e) => e.stopPropagation()}
                        >
                            <CompareArrowsIcon /> View Head-to-Head
                        </Link>
                    </div>
                </div>
                <div className="competitor-content">
                    <div className="preference-stats">
                        <div 
                            className="preference-bar"
                            role="group"
                            aria-label="Preference comparison"
                        >
                            <div 
                                className="preference-segment prefer-product" 
                                style={{ width: `${percentages.adjusted.pos}%` }}
                                title={`${percentages.display.pos}% prefer ${topicName}`}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onSegmentClick('positive', competitor);
                                }}
                                role="button"
                            />
                            <div 
                                className="preference-segment neutral" 
                                style={{ width: `${percentages.adjusted.neut}%` }}
                                title={`${percentages.display.neut}% neutral`}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onSegmentClick('neutral', competitor);
                                }}
                                role="button"
                            />
                            <div 
                                className="preference-segment prefer-competitor" 
                                style={{ width: `${percentages.adjusted.neg}%` }}
                                title={`${percentages.display.neg}% prefer ${competitor}`}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onSegmentClick('negative', competitor);
                                }}
                                role="button"
                            />
                        </div>
                        <div className="preference-labels">
                            <div className="preference-label">
                                <span className="preference-percentage">{percentages.display.pos}%</span>
                                <span className="preference-description">prefer {topicName}</span>
                            </div>
                            {percentages.display.neut > 0 && (
                                <div className="preference-label">
                                    <span className="preference-percentage">{percentages.display.neut}%</span>
                                    <span className="preference-description">neutral</span>
                                </div>
                            )}
                            <div className="preference-label">
                                <span className="preference-percentage">{percentages.display.neg}%</span>
                                <span className="preference-description">prefer {competitor}</span>
                            </div>
                        </div>
                    </div>
                    {summary && (
                        <div className="competitor-summary-text">
                            {formattedSummary}
                        </div>
                    )}
                </div>
                {hasChurnData && (
                    <div className="churn-indicator">
                        <button 
                            className="churn-arrow" 
                            onClick={handleChurnClick}
                            style={{ 
                                // transform: `translateX(${netChurn > 0 ? '10px' : '-10px'})`,
                                backgroundColor: netChurn > 0 ? '#4caf50' : '#f44336'
                            }}
                        >
                            {Math.abs(netChurn)} customers {netChurn > 0 ? 'gained from' : 'lost to'} {competitor}
                        </button>
                    </div>
                )}
                <div className="mention-count">
                    Based on {total} user {total === 1 ? 'mention' : 'mentions'}
                </div>
            </div>
            {expanded && (
                <div className="markdown-content">
                    <div className="markdown-header">
                        <h3>Detailed Comparison</h3>
                        <button 
                            className="close-button"
                            onClick={(e) => {
                                e.stopPropagation();
                                onToggle();
                            }}
                            aria-label="Close details"
                        >
                            <CloseIcon />
                        </button>
                    </div>
                    <ReactMarkdown>{competitorDetails[competitor]?.text || ''}</ReactMarkdown>
                </div>
            )}
        </div>
    );
};

const CompetitiveDifferentiators = ({ 
    topics, 
    allPosts, 
    hasTopicData, 
    companyName,
    companyId,
    companyTicker,
    userId 
}) => {
    const [expandedCompetitor, setExpandedCompetitor] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState("");
    const [filteredPosts, setFilteredPosts] = useState([]);
    const [filterTitle, setFilterTitle] = useState("");

    // Add useEffect to handle history state
    useEffect(() => {
        // Handler for popstate (back/forward) events
        const handlePopState = (event) => {
            clearFilteredPosts();
        };

        // Add event listener
        window.addEventListener('popstate', handlePopState);

        // Cleanup
        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, []);

    // Filter topics to only include those with competitive comparison analyses
    const validTopics = useMemo(() => {
        if (!topics) return [];
        return topics.filter(topic => {
            // Check if topic has competitive comparison details
            const hasCompetitiveDetails = Array.isArray(topic.details) && topic.details.some(
                detail => detail.type === 'competitive_comparison'
            );
            
            // Check if topic has posts with competitive assessments
            const hasCompetitivePosts = Array.isArray(topic.posts) && topic.posts.some(post => 
                Array.isArray(post.post_assessments) && post.post_assessments.some(
                    assessment => assessment.type === 'competitive_comparison'
                )
            );

            return hasCompetitiveDetails || hasCompetitivePosts;
        });
    }, [topics]);

    // Reset selected product if it becomes invalid
    useEffect(() => {
        if (selectedProduct && !validTopics.some(t => t.id === selectedProduct)) {
            setSelectedProduct("");
        }
    }, [validTopics, selectedProduct]);

    // Filter posts by selected product
    const selectedTopicPosts = useMemo(() => {
        if (!selectedProduct || !validTopics) return allPosts;
        
        const selectedTopic = validTopics.find(t => t.id === selectedProduct);
        if (!selectedTopic) return allPosts;
        
        // Return only posts that belong to the selected topic
        return selectedTopic.posts;
    }, [selectedProduct, validTopics, allPosts]);

    // Calculate market stats using filtered posts
    const marketStats = useMemo(() => {
        const stats = { total: 0, positive: 0, negative: 0, neutral: 0 };
        
        if (!Array.isArray(selectedTopicPosts)) return stats;

        selectedTopicPosts.forEach(post => {
            if (!post?.post_assessments) return;
            
            post.post_assessments.forEach(assessment => {
                if (!assessment) return;

                if (assessment.type === 'competitive_comparison' && assessment.details?.competitor) {
                    stats.total += 1;
                    const category = categorizeRating(assessment.rating);
                    stats[category] += 1;
                }
            });
        });

        return stats;
    }, [selectedTopicPosts]);

    // Get market overview details from '_self' comparison for selected product
    const marketOverview = useMemo(() => {
        if (!selectedProduct) {
            // If no product selected, look for market overview in all topics
            let overview = null;
            validTopics?.forEach(topic => {
                if (Array.isArray(topic.details)) {
                    topic.details.forEach(detail => {
                        if (detail.type === 'competitive_comparison' && detail.additional_text === '_self') {
                            overview = {
                                summary: detail.summary,
                                text: detail.text,
                                topicName: topic.topic
                            };
                        }
                    });
                }
            });
            return overview;
        }

        // If product selected, only look in that topic
        const selectedTopic = validTopics?.find(t => t.id === selectedProduct);
        if (!selectedTopic?.details || !Array.isArray(selectedTopic.details)) return null;

        const overview = selectedTopic.details.find(
            detail => detail.type === 'competitive_comparison' && detail.additional_text === '_self'
        );

        return overview ? {
            summary: overview.summary,
            text: overview.text,
            topicName: selectedTopic.topic
        } : null;
    }, [validTopics, selectedProduct]);

    // Calculate competitor stats using filtered posts
    const competitorStats = useMemo(() => {
        const stats = {};
        
        if (!Array.isArray(selectedTopicPosts)) return {};

        selectedTopicPosts.forEach(post => {
            if (!post?.post_assessments) return;
            
            post.post_assessments.forEach(assessment => {
                if (!assessment) return;

                if (assessment.type === 'competitive_comparison' && assessment.details?.competitor) {
                    const competitor = assessment.details.competitor;
                    if (!stats[competitor]) {
                        stats[competitor] = { total: 0, positive: 0, negative: 0, neutral: 0, churnFrom: 0, churnTo: 0 };
                    }
                    stats[competitor].total += 1;
                    
                    if (assessment.rating > 0) {
                        stats[competitor].positive += 1;
                    } else if (assessment.rating < 0) {
                        stats[competitor].negative += 1;
                    } else {
                        stats[competitor].neutral += 1;
                    }
                }
                else if (assessment.type === 'leaver_joiner' && assessment.details?.competitor) {
                    const competitor = assessment.details.competitor;
                    if (!stats[competitor]) {
                        stats[competitor] = { total: 0, positive: 0, negative: 0, neutral: 0, churnFrom: 0, churnTo: 0 };
                    }
                    if (assessment.rating > 0) {
                        stats[competitor].churnTo += 1;
                    } else if (assessment.rating < 0) {
                        stats[competitor].churnFrom += 1;
                    }
                }
            });
        });

        // Filter for meaningful sample size and limit to top competitors
        const filteredStats = Object.entries(stats)
            .filter(([_, value]) => value.total >= MIN_MENTIONS)
            .sort((a, b) => b[1].total - a[1].total)
            .slice(0, MAX_COMPETITORS)
            .reduce((acc, [key, value]) => {
                acc[key] = value;
                return acc;
            }, {});

        return filteredStats;
    }, [selectedTopicPosts]);

    const competitorDetails = useMemo(() => {
        const details = {};
        validTopics?.forEach(topic => {
            // Check if topic has details array
            if (Array.isArray(topic.details)) {
                // Find competitive comparison details
                topic.details.forEach(detail => {
                    if (detail.type === 'competitive_comparison' && detail.additional_text !== '_self') {
                        const competitor = detail.additional_text;
                        
                        // Only add if we have stats for this competitor
                        if (competitor && competitorStats[competitor]) {
                            details[competitor] = {
                                summary: detail.summary,
                                text: detail.text,
                                topic: topic.topic
                            };
                        }
                    }
                });
            }
        });
        return details;
    }, [validTopics, competitorStats]);

    const handleToggle = (competitor) => {
        setExpandedCompetitor(expandedCompetitor === competitor ? null : competitor);
    };

    // In the market overview section:
    const marketPercentages = adjustPercentages(marketStats.positive, marketStats.neutral, marketStats.negative, marketStats.total);

    // Get the product name from the selected product ID
    const getSelectedProductName = () => {
        if (!selectedProduct) return companyName;
        const selectedTopic = validTopics.find(t => t.id === selectedProduct);
        return selectedTopic?.topic || companyName;
    };

    // Calculate if there's any competitive data across all topics
    const hasAnyCompetitiveData = useMemo(() => {
        if (!Array.isArray(allPosts)) return false;
        return allPosts.some(post => 
            post?.post_assessments?.some(assessment => 
                assessment?.type === 'competitive_comparison'
            )
        );
    }, [allPosts]);

    const handleSegmentClick = (segmentType, competitor = null) => {
        let segmentFilteredPosts = [];
        let title = "";
        const selectedTopicName = getSelectedProductName();

        // Instantly scroll to top
        window.scrollTo(0, 0);

        // Add history state when showing filtered posts
        window.history.pushState({ filteredPostsView: true }, '', window.location.pathname + window.location.search);

        if (competitor) {
            // Filter posts for specific competitor
            segmentFilteredPosts = selectedTopicPosts.filter(post => {
                return post.post_assessments?.some(assessment => {
                    if (assessment.type !== 'competitive_comparison') return false;
                    if (assessment.details?.competitor !== competitor) return false;
                    
                    switch (segmentType) {
                        case 'positive':
                            return assessment.rating > RATING_THRESHOLDS.POSITIVE;
                        case 'neutral':
                            return assessment.rating >= RATING_THRESHOLDS.NEGATIVE && assessment.rating <= RATING_THRESHOLDS.POSITIVE;
                        case 'negative':
                            return assessment.rating < RATING_THRESHOLDS.NEGATIVE;
                        default:
                            return false;
                    }
                });
            }).map(post => {
                const assessment = post.post_assessments.find(a => 
                    a.type === 'competitive_comparison' && 
                    a.details?.competitor === competitor
                );
                return {
                    ...post,
                    rating: assessment?.rating,
                    confidence: assessment?.confidence,
                    explanation: assessment?.explanation,
                    ratingName: 'Competitive Comparison',
                    ratingType: 'competitive_comparison'
                };
            });

            title = `${segmentFilteredPosts.length} users ${
                segmentType === 'positive' ? `prefer ${selectedTopicName} over ${competitor}` :
                segmentType === 'neutral' ? `are neutral between ${competitor} and ${selectedTopicName}` :
                `prefer ${competitor} over ${selectedTopicName}`
            }`;
        } else {
            // Filter posts for market overview
            segmentFilteredPosts = selectedTopicPosts.filter(post => {
                return post.post_assessments?.some(assessment => {
                    if (assessment.type !== 'competitive_comparison') return false;
                    
                    switch (segmentType) {
                        case 'positive':
                            return assessment.rating > RATING_THRESHOLDS.POSITIVE;
                        case 'neutral':
                            return assessment.rating >= RATING_THRESHOLDS.NEGATIVE && assessment.rating <= RATING_THRESHOLDS.POSITIVE;
                        case 'negative':
                            return assessment.rating < RATING_THRESHOLDS.NEGATIVE;
                        default:
                            return false;
                    }
                });
            }).map(post => {
                const assessment = post.post_assessments.find(a => 
                    a.type === 'competitive_comparison'
                );
                return {
                    ...post,
                    rating: assessment?.rating,
                    confidence: assessment?.confidence,
                    explanation: assessment?.explanation,
                    ratingName: 'Competitive Comparison',
                    ratingType: 'competitive_comparison'
                };
            });

            title = `${segmentFilteredPosts.length} users ${
                segmentType === 'positive' ? `prefer ${selectedTopicName}` :
                segmentType === 'neutral' ? 'are neutral' :
                'prefer competitors'
            }`;
        }

        setFilteredPosts(segmentFilteredPosts);
        setFilterTitle(title);
    };

    const clearFilteredPosts = () => {
        setFilteredPosts([]);
        setFilterTitle("");
    };

    return (
        <div className="differentiators-content">
            {!hasAnyCompetitiveData ? (
                <NoDataMessage message="No competitive differentiator data available." />
            ) : (
                <>
                    {filteredPosts.length > 0 ? (
                        <>
                            <div className="page-header">
                                <div className="header-content">
                                    <div className="header-top">
                                        <button className="centered-icon-button gray-button" onClick={clearFilteredPosts}>
                                            <ArrowBackIcon className='icon'/>
                                            Back to Overview
                                        </button>
                                        {/* <h2 className="page-title">{filterTitle}</h2> */}
                                    </div>
                                    {/* <div className="subtitle">
                                        {filteredPosts.length} {filteredPosts.length === 1 ? 'post' : 'posts'}
                                    </div> */}
                                </div>
                            </div>
                            <FilteredPostsDisplay
                                title={filterTitle}
                                posts={filteredPosts}
                                onClose={clearFilteredPosts}
                                showBookmarkButton={true}
                                ratingDisplayName="Competitive Comparison"
                                id="competitive-filtered-posts"
                                ratingColorScheme={{ 
                                    high: RATING_THRESHOLDS.POSITIVE, 
                                    low: RATING_THRESHOLDS.NEGATIVE 
                                }}
                                hideHeader={false}
                                showCloseButton={false}
                            />
                        </>
                    ) : (
                        <>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                                <div>
                                    {validTopics && validTopics.length > 0 && (
                                        <ProductSelector
                                            products={validTopics}
                                            selectedProduct={selectedProduct}
                                            onProductChange={setSelectedProduct}
                                            className="product-selector"
                                            hideAllOption={validTopics.length === 1}
                                        />
                                    )}
                                </div>
                            </div>

                            {marketStats.total > 0 && (
                                <div className="market-overview-card competitor-card">
                                    <div className="competitor-header" onClick={() => handleToggle('market')}>
                                        <div className="competitor-title">
                                            <div className="competitor-name">Market Overview</div>
                                            <div className="competitor-actions">
                                                <Link 
                                                    to={`/company/${companyId}?section=head-to-head`}
                                                    className="head-to-head-link"
                                                    onClick={(e) => e.stopPropagation()}
                                                >
                                                    <CompareArrowsIcon /> View All Comparisons
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="competitor-content">
                                            <div className="preference-stats">
                                                <div className="preference-bar">
                                                    <div 
                                                        className="preference-segment prefer-product" 
                                                        style={{ width: `${marketPercentages.adjusted.pos}%` }}
                                                        title={`${marketPercentages.display.pos}% prefer ${getSelectedProductName()}`}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleSegmentClick('positive');
                                                        }}
                                                        role="button"
                                                    />
                                                    <div 
                                                        className="preference-segment neutral" 
                                                        style={{ width: `${marketPercentages.adjusted.neut}%` }}
                                                        title={`${marketPercentages.display.neut}% neutral`}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleSegmentClick('neutral');
                                                        }}
                                                        role="button"
                                                    />
                                                    <div 
                                                        className="preference-segment prefer-competitor" 
                                                        style={{ width: `${marketPercentages.adjusted.neg}%` }}
                                                        title={`${marketPercentages.display.neg}% prefer competitors`}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleSegmentClick('negative');
                                                        }}
                                                        role="button"
                                                    />
                                                </div>
                                                <div className="preference-labels">
                                                    <div className="preference-label">
                                                        <span className="preference-percentage">
                                                            {marketPercentages.display.pos}%
                                                        </span>
                                                        <span className="preference-description">prefer {getSelectedProductName()}</span>
                                                    </div>
                                                    {marketPercentages.display.neut > 0 && (
                                                        <div className="preference-label">
                                                            <span className="preference-percentage">
                                                                {marketPercentages.display.neut}%
                                                            </span>
                                                            <span className="preference-description">neutral</span>
                                                        </div>
                                                    )}
                                                    <div className="preference-label">
                                                        <span className="preference-percentage">
                                                            {marketPercentages.display.neg}%
                                                        </span>
                                                        <span className="preference-description">prefer competitors</span>
                                                    </div>
                                                </div>
                                            </div>
                                            {marketOverview?.summary && (
                                                <div className="competitor-summary-text">
                                                    {formatSummaryText(marketOverview.summary)}
                                                </div>
                                            )}
                                        </div>
                                        <div className="mention-count">
                                            Based on {marketStats.total} competitive {marketStats.total === 1 ? 'comparison' : 'comparisons'}
                                        </div>
                                    </div>
                                    {expandedCompetitor === 'market' && marketOverview?.text && (
                                        <div className="markdown-content">
                                            <div className="markdown-header">
                                                <h3>Market Position Details</h3>
                                                <button 
                                                    className="close-button"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleToggle('market');
                                                    }}
                                                    aria-label="Close details"
                                                >
                                                    <CloseIcon />
                                                </button>
                                            </div>
                                            <ReactMarkdown>{marketOverview.text}</ReactMarkdown>
                                        </div>
                                    )}
                                </div>
                            )}
                            {Object.keys(competitorStats).length > 0 && (
                                <div className="competitor-grid">
                                    {Object.entries(competitorStats).map(([competitor, stats]) => (
                                        <CompetitiveSummary
                                            key={competitor}
                                            competitor={competitor}
                                            stats={stats}
                                            expanded={expandedCompetitor === competitor}
                                            onToggle={() => handleToggle(competitor)}
                                            companyId={companyId}
                                            summary={competitorDetails[competitor]?.summary}
                                            competitorDetails={competitorDetails}
                                            companyName={companyName}
                                            topicName={getSelectedProductName()}
                                            posts={selectedTopicPosts}
                                            onSegmentClick={handleSegmentClick}
                                        />
                                    ))}
                                </div>
                            )}
                        </>
                    )}
                </>
            )}
        </div>
    );
};

export default CompetitiveDifferentiators; 