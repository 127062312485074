import React from 'react';
import FilteredPostsDisplay from './FilteredPostsDisplay';
import NoDataMessage from './NoDataMessage';
import BackToTop from './BackToTop';

const MIN_NEWS_RELEVANCE = 4;

const CompanyNews = ({ 
    allPosts,
    companyName,
    companyId,
    companyTicker,
    userId
}) => {
    const getRelevantNews = () => {
        if (!Array.isArray(allPosts)) return [];
        
        return allPosts
            .filter(post => 
                post?.post_assessments?.some(assessment => 
                    assessment?.type === 'relevance' && 
                    assessment?.rating >= MIN_NEWS_RELEVANCE
                )
            )
            .sort((a, b) => (b?.time || 0) - (a?.time || 0));
    };

    const relevantNews = getRelevantNews();

    return relevantNews.length > 0 ? (
        <div className="news-section">
            <FilteredPostsDisplay 
                posts={relevantNews}
                showBookmarkButton={true}
                hideHeader={true}
                showSortControls={true}
                defaultSortMethod="newest"
            />
            <BackToTop />
        </div>
    ) : (
        <NoDataMessage 
            topic="news and analysis"
            companyName={companyName}
            companyId={companyId}
            companyTicker={companyTicker}
            userId={userId}
            dataType="NEWS_DATA"
        />
    );
};

export default CompanyNews; 