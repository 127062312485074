import React, { useMemo, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import FilteredPostsDisplay from './FilteredPostsDisplay';
import '../styles/CustomAssessmentDisplay.css';

// T-test implementation
const calculateTTest = (data) => {
    if (!data || data.length < 2) return { significant: false, pValue: 1 };

    // Calculate mean
    const mean = data.reduce((sum, d) => sum + d.rating, 0) / data.length;
    
    // Calculate standard deviation
    const squaredDiffs = data.map(d => Math.pow(d.rating - mean, 2));
    const variance = squaredDiffs.reduce((sum, diff) => sum + diff, 0) / (data.length - 1);
    const stdDev = Math.sqrt(variance);
    
    // Calculate standard error
    const standardError = stdDev / Math.sqrt(data.length);
    
    // Calculate t-statistic
    const tStat = mean / standardError;
    
    // Calculate degrees of freedom
    const df = data.length - 1;
    
    // Calculate p-value using t-distribution approximation
    // This is a simplified two-tailed test approximation
    const pValue = 2 * (1 - normalCDF(Math.abs(tStat)));
    
    return {
        significant: pValue < 0.05,
        pValue: pValue,
        tStat: tStat,
        mean: mean,
        stdDev: stdDev,
        df: df
    };
};

// Helper function for normal CDF approximation
const normalCDF = (x) => {
    const t = 1 / (1 + 0.2316419 * Math.abs(x));
    const d = 0.3989423 * Math.exp(-x * x / 2);
    const prob = d * t * (0.3193815 + t * (-0.3565638 + t * (1.781478 + t * (-1.821256 + t * 1.330274))));
    return x > 0 ? 1 - prob : prob;
};

const CustomAssessmentDisplay = ({ data, metricName, description, ratingFormat = 'outOfTen' }) => {
    const [selectedBin, setSelectedBin] = useState(null);
    const [showPostDisplay, setShowPostDisplay] = useState(true);

    // Process data for histogram with better bin organization
    const histogramData = useMemo(() => {
        if (!data || data.length === 0) return null;

        const binSize = 1000; // $1,000 intervals
        const bins = {};
        
        // Find min and max for complete range
        const minRating = Math.floor(Math.min(...data.map(d => d.rating)) / binSize) * binSize;
        const maxRating = Math.ceil(Math.max(...data.map(d => d.rating)) / binSize) * binSize;
        
        // Initialize all bins to ensure complete range
        for (let bin = minRating; bin <= maxRating; bin += binSize) {
            bins[bin] = 0;
        }
        
        // Fill in actual data
        data.forEach(d => {
            const bin = Math.floor(d.rating / binSize) * binSize;
            bins[bin] = (bins[bin] || 0) + 1;
        });

        return Object.entries(bins)
            .map(([bin, count]) => ({
                priceRange: `$${parseInt(bin).toLocaleString()} - $${(parseInt(bin) + binSize).toLocaleString()}`,
                count: count,
                binStart: parseInt(bin),
                isPositive: parseInt(bin) >= 0
            }))
            .sort((a, b) => a.binStart - b.binStart);
    }, [data]);

    // Filter posts based on selected bin
    const filteredPosts = useMemo(() => {
        if (!selectedBin) return data;
        
        const binSize = 1000;
        return data.filter(post => {
            const postBin = Math.floor(post.rating / binSize) * binSize;
            return postBin === selectedBin;
        });
    }, [data, selectedBin]);

    // Process posts to include all required fields
    const processedPosts = useMemo(() => {
        return filteredPosts.map(post => ({
            ...post, // Preserve all original post fields
            ratingName: "Price Difference",
            explanation: post.explanation || `This post shows a price difference of $${post.rating.toLocaleString()}`,
            confidence: post.confidence || 0.8,
            // Ensure required fields are present
            title: post.title || "",
            text: post.text || "",
            url: post.url || "",
            time: post.time || new Date().toISOString(),
            id: post.id,
            type: post.type || "custom",
            topic_id: post.topic_id,
            ratingType: post.ratingType || "price_difference"
        }));
    }, [filteredPosts]);

    if (!histogramData) return null;

    const handleBarClick = (entry) => {
        const binStart = entry.binStart;
        setSelectedBin(selectedBin === binStart ? null : binStart);
    };

    const handleClosePostsDisplay = () => {
        setShowPostDisplay(false);
    };

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip">
                    <p className="price-range">{label}</p>
                    <p className="post-count">{`${payload[0].value} posts`}</p>
                </div>
            );
        }
        return null;
    };

    // Calculate stats with significance testing
    const avgPriceDiff = data.reduce((sum, d) => sum + d.rating, 0) / data.length;
    const positiveDiffs = data.filter(d => d.rating > 0).length;
    const negativeDiffs = data.filter(d => d.rating < 0).length;
    const positivePercentage = Math.round((positiveDiffs / data.length) * 100);
    const negativePercentage = Math.round((negativeDiffs / data.length) * 100);
    
    // Perform significance test
    const testResults = calculateTTest(data);

    return (
        <div className="price-comparison-display">
            <div className="price-histogram-section">
                <h2>Price Difference Distribution</h2>
                <div className="price-stats">
                    <div className="stat">
                        <span className="label">Sample Size</span>
                        <span className="value">{data.length} posts</span>
                    </div>
                    <div className="stat">
                        <span className="label">Average Difference</span>
                        <div className="value-container">
                            <span className="value" style={{ color: avgPriceDiff >= 0 ? '#2e7d32' : '#c62828' }}>
                                {avgPriceDiff >= 0 ? '+' : ''}{avgPriceDiff.toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                    maximumFractionDigits: 0
                                })}
                            </span>
                            {testResults.significant && (
                                <span className="significance-indicator" title={`p-value: ${testResults.pValue.toExponential(2)}`}>
                                    *
                                </span>
                            )}
                        </div>
                        <span className="significance-note">
                            {testResults.significant ? 
                                `Statistically significant (p < 0.05)` : 
                                'Not statistically significant'}
                        </span>
                    </div>
                    <div className="stat">
                        <span className="label">Higher Price</span>
                        <span className="value">{positivePercentage}% ({positiveDiffs})</span>
                    </div>
                    <div className="stat">
                        <span className="label">Lower Price</span>
                        <span className="value">{negativePercentage}% ({negativeDiffs})</span>
                    </div>
                </div>
                <div className="histogram-container">
                    <ResponsiveContainer width="100%" height={300}>
                        <BarChart 
                            data={histogramData}
                            margin={{ top: 20, right: 30, left: 20, bottom: 70 }}
                        >
                            <CartesianGrid strokeDasharray="3 3" stroke="#eee" />
                            <XAxis 
                                dataKey="priceRange" 
                                angle={-45}
                                textAnchor="end"
                                height={80}
                                interval={0}
                                tick={{ fontSize: 12 }}
                            />
                            <YAxis 
                                tick={{ fontSize: 12 }}
                                label={{ 
                                    value: 'Number of Posts', 
                                    angle: -90, 
                                    position: 'insideLeft',
                                    style: { fontSize: 12 }
                                }}
                            />
                            <Tooltip content={<CustomTooltip />} />
                            <Bar 
                                dataKey="count" 
                                fill={(entry) => entry.binStart >= 0 ? '#4caf50' : '#ef5350'}
                                opacity={selectedBin !== null ? (entry) => entry.binStart === selectedBin ? 1 : 0.3 : 1}
                                onClick={handleBarClick}
                                style={{ cursor: 'pointer' }}
                            />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
                {selectedBin !== null && (
                    <div className="filter-notice">
                        <span>
                            Showing {processedPosts.length} posts with price difference between 
                            <strong> ${selectedBin.toLocaleString()}</strong> and 
                            <strong> ${(selectedBin + 1000).toLocaleString()}</strong>
                        </span>
                        <button onClick={() => setSelectedBin(null)} className="clear-filter">
                            Clear Filter
                        </button>
                    </div>
                )}
            </div>

            {showPostDisplay && (
                <FilteredPostsDisplay 
                    posts={processedPosts}
                    onClose={handleClosePostsDisplay}
                    id="price-comparison-posts"
                    title={selectedBin !== null ? 
                        `Posts with Price Difference: $${selectedBin.toLocaleString()} - $${(selectedBin + 1000).toLocaleString()}` : 
                        "All Price Comparison Posts"
                    }
                    ratingDisplayName="Price Difference"
                    autoScroll={true}
                    ratingColorScheme={{ high: 0, low: 0 }}
                    showSortControls={true}
                    ratingFormat={ratingFormat}
                />
            )}
        </div>
    );
};

export default CustomAssessmentDisplay; 